/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from "react-router-dom";

import getLang from "src/utils/languages";
import appDecl from "src/utils/declarations";
import generalFxs from "src/utils/general_fx";

import imgLogo from 'src/assets/images/official_logo.png';
import {ReactComponent as FrFlag} from 'src/assets/images/svg/fr-flag.svg';
import {ReactComponent as UkFlag} from 'src/assets/images/svg/uk-flag.svg';

const languages = getLang();
const appFxs = generalFxs();

export default function WebSiteFooter() {
  const changeLang = (lng: string) => {
    appFxs.setLocalStorageByKey(appDecl.currentLang, lng);
    document.location.reload();
  }

  
  return (
    
    <div className="bg-[#f2f2f2]">
    <div className="container mx-auto">
      <footer className="footer p-10 bg-base-200 text-base-content">
        <aside>
          <img src={imgLogo} alt="" className='w-[60px] h-[60px]'/>
          <p>
            <strong className='text-lg'>Archivix</strong>
            <br />
            {languages.doctMgtApp}
          </p>
          <p className='flex items-center gap-3'>
            <a onClick={() => changeLang('en')} 
              className='flex items-center gap-2 cursor-pointer text-blue-700 hover:text-blue-900'>
                <UkFlag width={22} height={16} />
              <span>{languages.english_m}</span>
            </a>
            <a onClick={() => changeLang('fr')} 
              className='flex gap-2 items-center cursor-pointer text-blue-700 hover:text-blue-900'>
                <FrFlag width={22} height={16} />
              <span>{languages.french_m}</span>
            </a>
          </p>
        </aside>
        <nav>
          <h6 className="footer-title">{languages.menu}</h6>
          <Link to="/support" className="link link-hover">{languages.support}</Link>
          <Link to="/pricing" className="link link-hover">{languages.pricing}</Link>
          <Link to="/blog" className="link link-hover">{languages.blog}</Link>
          <Link to="/contactus" className="link link-hover">{languages.contactUs}</Link>
        </nav>
        <nav>
          <h6 className="footer-title">{languages.legal}</h6>
          <Link to='/legal/terms' className="link link-hover">{languages.termsOfUse}</Link>
          <Link to='/legal/privacy' className="link link-hover">{languages.privPolicySh}</Link>
          <Link to='/legal/cookies' className="link link-hover">{languages.cookiePolicy}</Link>
        </nav>
        <nav>
          <h6 className="footer-title">{languages.websites}</h6>
          <a target='_blank' href='https://wiseobjects.com' rel="noreferrer" className="link link-hover">WiseObjects</a>
          <a target='_blank' href='https://jkayihura.wiseobjects.com' rel="noreferrer" className="link link-hover">{languages.developper}</a>
          <a target='_blank' href='https://wiseclinic.com' rel="noreferrer" className="link link-hover">Wise Clinic</a>
          <a target='_blank' href='https://wisemanager.com' rel="noreferrer" className="link link-hover">Wise Manager</a>
        </nav>
      </footer>
    </div>
  </div>
  )
}
