/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import logoColor from "src/assets/images/general/archivix_logo_small_black.png";

import {ReactComponent as FrFlagSvg} from "src/assets/images/svg/fr-flag.svg";
import {ReactComponent as UkFlagSvg} from "src/assets/images/svg/uk-flag.svg";

import appDecl from "src/utils/declarations";
import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

const languages = getLang();
const appFxs = generalFxs();

interface IWebMobileMenu{
    visible: boolean,
    onMenuClick: (addr: string) => void,
    setMobileMenuVis: (bVis: boolean) => void,
}

export default function WebMobileMenu({visible, onMenuClick, setMobileMenuVis}: IWebMobileMenu) {
  const handleChangeLng = (lng: string) => {
    appFxs.setLocalStorageByKey(appDecl.currentLang, lng);
    document.location.reload();
  }
  return (
    <div data-visible={visible}
        className="hidden data-[visible=true]:fixed data-[visible=true]:inset-0 z-50 data-[visible=true]:flex data-[visible=true]:justify-end ">
        <div
          data-state="open"
          className="bg-background/80 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in fixed inset-0 z-50 backdrop-blur-sm transition-all duration-100"
          style={{ pointerEvents: "auto" }}
          aria-hidden="true"
        ></div>
        <div
          className="fixed z-50 scale-100 gap-4 bg-background p-6 opacity-100 shadow-lg border animate-in slide-in-from-right h-full duration-300 w-full max-w-[400px]"
          role="dialog"
          tabIndex={-1}
          id="mobilemenu"
        >
          <a onClick={() => onMenuClick("/")} className="z-10 cursor-pointer">
            <img src={logoColor} alt="" className="w-[170px]" />
          </a>
          <div className="mt-12 flex w-full flex-col items-start gap-y-4">
            <div className="opacity: 1; transform: none;">
              <a
                onClick={() => onMenuClick("/support")}
                className="text-foreground hover:text-foreground/80 text-2xl font-semibold cursor-pointer"
              >
                {languages.support}
              </a>
            </div>
            {/* pricing */}
            <div className="opacity: 1; transform: none;">
              <a
                onClick={() => onMenuClick("/pricing")}
                className="text-foreground hover:text-foreground/80 text-2xl font-semibold cursor-pointer"
              >
                {languages.pricing}
              </a>
            </div>

{/* blog */}
            <div className="opacity: 1; transform: none;">
              <a
                onClick={() => onMenuClick("/blog")}
                className="text-foreground hover:text-foreground/80 text-2xl font-semibold cursor-pointer"
              >
                {languages.blog}
              </a>
            </div>

            {/* contact us */}
            <div className="opacity: 1; transform: none;">
              <a
                onClick={() => onMenuClick("/contactus")}
                className="text-foreground hover:text-foreground/80 text-2xl font-semibold cursor-pointer"
              >
                {languages.contactUs}
              </a>
            </div>

            {/* sign in */}
            <div className="opacity: 1; transform: none;">
              <a
                target="_blank"
                className="text-foreground hover:text-foreground/80 text-2xl font-semibold"
                href={`${appDecl.mainAppPath}/account/login`}
                rel="noreferrer"
              >
                {languages.signIn}
              </a>
            </div>
          </div>
          <div className="mx-auto mt-8 flex w-full flex-wrap items-center gap-x-4 gap-y-4 ">
            <a
              className="text-foreground hover:text-foreground/80 cursor-pointer"
              title={languages.french_m}
              onClick={() => handleChangeLng('fr')}
            >
              <FrFlagSvg className="h-6 w-6" />
            </a>
            <a
              className="text-foreground hover:text-foreground/80 cursor-pointer"
              title={languages.english_m}
              onClick={() => handleChangeLng('en')}
            >
              <UkFlagSvg className="h-6 w-6" />
            </a>
          </div>
          <button
            type="button"
            className="ring-offset-background focus:ring-ring data-[state=open]:bg-secondary absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none"
            onClick={() => setMobileMenuVis(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-4 w-4"
            >
              <path d="M18 6 6 18"></path>
              <path d="m6 6 12 12"></path>
            </svg>
            <span className="sr-only">{languages.close}</span>
          </button>
        </div>
      </div>
  )
}
