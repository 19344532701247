import React from "react";

import getLang from "src/utils/languages";

import congoDoctsImg from "src/assets/images/congo_docts_img.png";
import laColombImg from "src/assets/images/lacolombiere_school_logo.png";
import { Link } from "react-router-dom";

const languages = getLang();

const lstCustomers: {
  title: string;
  link: string;
  img: string;
}[] = [
  {
    link: "https://congodocuments.net/",
    title: "Congo Documents",
    img: congoDoctsImg,
  },
  {
    link: "https://lacolombiere.ac.rw",
    title: "Archivix",
    img: laColombImg,
  },
];

export default function CustomerSpecComp() {
  return (
    <div className="flex flex-col space-y-4 md:mx-4">
      <h3 className="text-[28px] font-semibold leading-[1.215]">
        {languages.ourCustomers}
      </h3>
      <p className="md:py-[10px]">{languages.ourCustomersMsg}</p>
      <div className="text-center md:flex items-center flex-wrap justify-center">
        {lstCustomers.map((o, idx) => (
          <a
            key={idx}
            rel="noreferrer"
            target="_blank"
            href={o.link}
            className="inline m-[.7em]"
            title={o.title}
          >
            <img src={o.img} alt="" className="w-auto max-h-[75px]" />
          </a>
        ))}
      </div>
    </div>
  );
}

interface IDoctManagtExplain {
  lang: string;
}
export const DoctManagtExplain = ({ lang }: IDoctManagtExplain) => {
  return (
    <div className="flex flex-col space-y-4">
      <h3 className="text-[28px] font-semibold leading-[1.215]">
        {lang === "en" ? (
          <>Archivix - Document management software and signature automation</>
        ) : (
          <>
            Archivix - Logiciel de gestion documentaire et automatisation des
            signatures
          </>
        )}
      </h3>
      <p data-lang={lang} className="my-[10p] data-[lang='fr']:hidden">
        Archivix provides cloud <strong>document management</strong> solutions
        and <strong>signature automation</strong> software that enables you to
        digitize, secure and work with business documents, then optimize the
        signaure processes to collaborate with employees and partners.
      </p>
      <p data-lang={lang} className="my-[10p] data-[lang='en']:hidden">
        Archivix fournit des solutions de <strong>gestion de documents</strong>
        &nbsp; cloud et un logiciel d'
        <strong>automatisation de signature</strong> qui vous permettent de
        numériser, sécuriser et travailler avec des documents professionnels,
        puis d'optimiser les processus de signature pour collaborer avec les
        employés et les partenaires.
      </p>
      <p data-lang={lang} className="my-[10p] data-[lang='fr']:hidden">
        From organizing files to collaboration, it's the ultimate tool for
        boosting productivity; your team is free to focus on the work that
        drives productivity and profit.&nbsp;
        <span className="highlighter">
          Archivix is perfect for schools, insurance companies, financial
          companies that deal on a daily basis with distributed and remote teams.
        </span>
      </p>
      <p data-lang={lang} className="my-[10p] data-[lang='en']:hidden">
        De l'organisation des fichiers à la collaboration, c'est l'outil ultime
        pour augmenter la productivité votre équipe est libre de se concentrer
        sur le travail qui génère la productivité et le profit.&nbsp;
        <span className="highlighter">
          Archivix est parfait pour les écoles, les compagnies d'assurance, les
          sociétés financières qui traitent au quotidien avec des équipes
          distribuées et distantes.
        </span>
      </p>

      <p className="my-[10p]">
        {lang === "en" ? (
          <>
            Manage what matters in Archivix, the true home for your documents.
          </>
        ) : (
          <>
            Gérez ce qui compte dans Archivix, le véritable domicile de vos
            documents.
          </>
        )}
      </p>
    </div>
  );
};

interface IArchivixLinkSoluLearn {
  lang: string;
}
export const ArchivixLinkSoluLearn = ({ lang }: IArchivixLinkSoluLearn) => {
  return (
    <div className="w-full py-5 flex flex-col space-y-3">
      <div className="flex flex-wrap">
        <div className="w-full md:w-[50%] flex flex-col">
          <div className="flex flex-col md:pr-5">
            <h3 className="text-[28px] font-semibold leading-[1.215]">
              {languages.coreBusDoctM}
            </h3>
            <p className="my-[10px]">{languages.doctManSol}:</p>
            <h5 className="text-[1em] mb-[.4em]">
              <Link className="text-blue-600 hover:text-blue-900" to="/">
                {languages.schoolBusdocts}
              </Link>
            </h5>
            <p className="text-[#666] text-[85%] mb-4">
              {languages.schoolBusdoctsMsg}
            </p>

            <h5 className="text-[1em] mb-[.4em]">
              <Link className="text-blue-600 hover:text-blue-900" to="/">
                {languages.humanResourc}
              </Link>
            </h5>
            <p className="text-[#666] text-[85%] mb-4">
              {languages.humanResourcMsg}
            </p>

            <h5 className="text-[1em] mb-[.4em]">
              <Link className="text-blue-600 hover:text-blue-900" to="/">
                {languages.insuranceBusiness}
              </Link>
            </h5>
            <p className="text-[#666] text-[85%] mb-4">
              {languages.insuranceBusinessMsg}
            </p>
          </div>
        </div>
        <div className="w-full md:w-[50%] flex flex-col">
          <div className="flex flex-col md:pl-5">
            <h3 className="text-[28px] font-semibold leading-[1.215]">
              {lang === "en" ? (
                <>Mastering Document Management: Enhancing Efficiency in Handling Information</>
              ) : (
                <>Maîtriser la gestion documentaire: améliorer l'efficacité du traitement de l'information</>
              )}
            </h3>
            <p className="my-[10px]">
              {lang === "en" ? (
                <>Understanding the Essentials and Best Practices of Document Management Systems</>
              ) : (
                <>Comprendre les éléments essentiels et les meilleures pratiques des systèmes de gestion de documents</>
              )}
            </p>

            <h5 className="text-[1em] mb-[.4em]">
              <Link className="text-blue-600 hover:text-blue-900" to="/">
              {lang === "en" ? (
                <>Introduction to Document Management</>
              ) : (
                <>Introduction à la gestion documentaire</>
              )}
              </Link>
            </h5>
            <p className="text-[#666] text-[85%] mb-4">
            {lang === "en" ? (
                <>Defining Key Concepts, Benefits, and Components of Effective Systems</>
              ) : (
                <>Définir les concepts clés, les avantages et les composants de systèmes efficaces</>
              )}
            </p>


            <h5 className="text-[1em] mb-[.4em]">
              <Link className="text-blue-600 hover:text-blue-900" to="/">
              {lang === "en" ? (
                <>Implementing Document Management Strategies</>
              ) : (
                <>Mise en œuvre de stratégies de gestion de documents</>
              )}
              </Link>
            </h5>
            <p className="text-[#666] text-[85%] mb-4">
            {lang === "en" ? (
                <>Streamlining Workflows, Ensuring Compliance, and Enhancing Collaboration</>
              ) : (
                <>Rationaliser les flux de travail, garantir la conformité et améliorer la collaboration</>
              )}
            </p>

            <h5 className="text-[1em] mb-[.4em]">
              <Link className="text-blue-600 hover:text-blue-900" to="/">
              {lang === "en" ? (
                <>Advanced Features and Future Trends</>
              ) : (
                <>Fonctionnalités avancées et tendances futures</>
              )}
              </Link>
            </h5>
            <p className="text-[#666] text-[85%] mb-4">
            {lang === "en" ? (
                <>Exploring Automation, Integration, and Innovations in Document Management Solutions</>
              ) : (
                <>Explorer l'automatisation, l'intégration et les innovations dans les solutions de gestion de documents</>
              )}
            </p>

            
          </div>
        </div>
      </div>
    </div>
  );
};
