import React, { useEffect } from "react";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

import { WiseActionButtonV2 } from "src/components/button_components";

import bg2 from "src/assets/images/blog_bg.jpg";
import { useNavigate } from "react-router-dom";

const languages = getLang();
const appFxs = generalFxs();

const loclang = appFxs.getLocalLanguage();

function BlogPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.blog} | Archivix`;
  }, []);

  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="relative bg-left  bg-no-repeat bg-cover"
      >
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">{languages.blog}</h3>
          <p
            data-lang={loclang}
            className="data-[lang='fr']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Step into the world of efficient document management with us.
            Explore tips, tricks, and industry insights to streamline your
            workflow and maximize productivity. We're excited to have you on
            board!
          </p>
          <p
            data-lang={loclang}
            className="data-[lang='en']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Entrez avec nous dans le monde de la gestion efficace des documents.
            Découvrez des conseils, des astuces et des informations sur le
            secteur pour rationaliser votre flux de travail et maximiser la
            productivité. Nous sommes ravis de vous compter à bord!
          </p>
        </div>
      </div>
      <div className="min-h-screen bg-gray-800 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 w-full  max-w-[576px] sm:mx-auto">
        <div className="flex flex-col justify-center space-y-2">
              <h3 className="text-white text-3xl text-center mb-2">
                {languages.noPostBlogYet}
              </h3>
              <WiseActionButtonV2
                label={languages.home}
                handleClick={() => navigate("/home")}
              />
            </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPage;
