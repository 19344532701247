/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { ReactComponent as ChekOpenSvg } from "src/assets/images/svg/check-open.svg";
import { ReactComponent as MagicCircleSvg } from "src/assets/images/svg/magic-circle.svg";

import FreeMobileTools from "src/components/FreeMobileTools";
import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import bg2 from "src/assets/images/pricing_bg.jpg";
import { WisePricingBtn } from "src/components/button_components";

const languages = getLang();
const appFxs = generalFxs();

const loclang = appFxs.getLocalLanguage();

function PricingPage() {
  const [paytType, setPaytType] = useState<"annual" | "month">("annual");

  const onStartBill = () => {
    const sPath = `${appDecl.registerPath}`;
    appFxs.openWindowUrlTab(sPath);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.pricing} | Archivix`;
  }, []);

  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="relative bg-left  bg-no-repeat bg-cover"
      >
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">{languages.pricing}</h3>
          <p
            data-lang={loclang}
            className="data-[lang='fr']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Discover the value-packed pricing plans tailored just for you.
            Unlock the power of Archivix without breaking the bank. Get started
            today and revolutionize your document management experience!
          </p>
          <p
            data-lang={loclang}
            className="data-[lang='en']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Découvrez les plans tarifaires avantageux conçus spécialement pour
            vous. Libérez la puissance d'Archivix sans vous ruiner. Commencez
            dès aujourd'hui et révolutionnez votre expérience de gestion
            documentaire!
          </p>
        </div>
      </div>
      <div className="container mx-auto py-[60px]">
        <div className="space-y-5 px-8">
          <div className="container flex space-x-2">
            <div className="no-scrollbar overflow-hidden">
              <div className="flex no-scrollbar justify-start space-x-6 items-center overflow-x-auto">
                <button
                  data-payt={paytType}
                  onClick={() => setPaytType("month")}
                  className="font-semibold whitespace-nowrap text-base text-gray-500 data-[payt='month']:text-gray-900 hover:text-gray-600 transition-colors duration-300"
                >
                  {languages.monthly}
                </button>
                <button
                  data-payt={paytType}
                  onClick={() => setPaytType("annual")}
                  className="font-semibold whitespace-nowrap text-base text-gray-500 data-[payt='annual']:text-gray-900 hover:text-gray-600 transition-colors duration-300 "
                >
                  {languages.annually}
                </button>
              </div>
            </div>
            <button className="text-purple-500 font-semibold text-base">
              ({languages.twoWeeksFree})
            </button>
          </div>
          <div className="demo-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {/* Starter */}
            <div className="rounded-[30px] md:rounded-[36px] bg-[#FAFAFA] overflow-hidden border-[1px] border-gray-200 p-8 relative">
              <div className="h-full">
                <div className="h-full z-10 relative">
                  <div className="flex flex-col flex-1 justify-between h-full space-y-5">
                    <div className="flex justify-between flex-col">
                      <div className="text-xl md:text-2xl font-bold text-gray-900 flex justify-between">
                        <span>{languages.starter}</span>
                      </div>
                      <div className="pt-5 text-gray-500 font-medium text-base space-y-1">
                        <div className="flex items-center align-bottom">
                          <span className="pt-1.5">$</span>
                          <div className="ml-1 mr-2 text-2xl md:text-3xl font-bold text-gray-900">
                            <span>{paytType === "annual" ? 10 : 13}</span>
                          </div>
                          <span className="pt-1.5">
                            {languages.perMonthUser}
                          </span>
                        </div>
                        <div className="text-base">
                          {paytType === "annual"
                            ? languages.billedAnn
                            : languages.billedMon}
                        </div>
                      </div>
                      <div className="">
                        <ul className="space-y-2 pt-8">
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.everInFree}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.fiftyDocsMonth}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.unlimUsers}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="pt-2">
                      <WisePricingBtn onClickAction={onStartBill} label={languages.chooseStarter} />
                    </div>
                  </div>
                  <div className="hidden">
                    <ul className="space-y-2 pt-8">
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>Everything in Free</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>5,000 events / month</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>Unlimited seats</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Startup */}
            <div className="rounded-[30px] md:rounded-[36px] bg-[#FAFAFA] overflow-hidden border-[1px] border-gray-200 p-8 relative">
              <div className="h-full">
                <div className="h-full z-10 relative">
                  <div className="flex flex-col flex-1 justify-between h-full space-y-5">
                    <div className="flex justify-between flex-col">
                      <div className="text-xl md:text-2xl font-bold text-gray-900 flex justify-between">
                        <span>{languages.startup}</span>
                        <MagicCircleSvg />
                      </div>
                      <div className="pt-5 text-gray-500 font-medium text-base space-y-1">
                        <div className="flex items-center align-bottom">
                          <span className="pt-1.5">$</span>
                          <div className="ml-1 mr-2 text-2xl md:text-3xl font-bold text-gray-900">
                            <span>{paytType === "month" ? 35 : 28}</span>
                          </div>
                          <span className="pt-1.5">
                            {languages.perMonthUser}
                          </span>
                        </div>
                        <div className="text-base">
                          {paytType === "month"
                            ? languages.billedMon
                            : languages.billedAnn}
                        </div>
                      </div>
                      <div className="">
                        <ul className="space-y-2 pt-8">
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.everyThStarter}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>
                              500 {languages.documents.toLowerCase()} /&nbsp;
                              {languages.month.toLowerCase()}
                            </span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>
                              500 {languages.signatures.toLowerCase()} /&nbsp;
                              {languages.month.toLowerCase()}
                            </span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>
                              500 {languages.partnOps.toLowerCase()} /&nbsp;
                              {languages.month.toLowerCase()}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="pt-2">
                      <WisePricingBtn onClickAction={onStartBill} label={languages.chooseStartup} />
                    </div>
                  </div>
                  <div className="hidden">
                    <ul className="space-y-2 pt-8">
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.everyThStarter}</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg />
                        <span>
                          500 {languages.documents.toLowerCase()} /&nbsp;
                          {languages.month.toLowerCase()}
                        </span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>
                          500 {languages.signatures.toLowerCase()} /&nbsp;
                          {languages.month.toLowerCase()}
                        </span>
                      </li>

                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>
                          500 {languages.partnOps.toLowerCase()} /&nbsp;
                          {languages.month.toLowerCase()}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Professional */}
            <div className="rounded-[30px] md:rounded-[36px] bg-[#FAFAFA] overflow-hidden border-[1px] border-gray-200 p-8 relative">
              <div className="h-full">
                <div className="h-full z-10 relative">
                  <div className="flex flex-col flex-1 justify-between h-full space-y-5">
                    <div className="flex justify-between flex-col">
                      <div className="text-xl md:text-2xl font-bold text-gray-900 flex justify-between">
                        <span>{languages.professional}</span>
                      </div>
                      <div className="pt-5 text-gray-500 font-medium text-base space-y-1">
                        <div className="flex items-center align-bottom">
                          <span className="pt-1.5">$</span>
                          <div className="ml-1 mr-2 text-2xl md:text-3xl font-bold text-gray-900">
                            <span>{paytType === "month" ? 60 : 50}</span>
                          </div>
                          <span className="pt-1.5">
                            {languages.perMonthUser}
                          </span>
                        </div>
                        <div className="text-base">
                          {paytType === "month"
                            ? languages.billedMon
                            : languages.billedAnn}
                        </div>
                      </div>
                      <div className="">
                        <ul className="space-y-2 pt-8">
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.everyInStart}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.unlimitUsers}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.unlimitDocts}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.unlimitParts}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.digitalSignature}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="pt-2">
                      <WisePricingBtn onClickAction={onStartBill} label={languages.choosePro} />
                    </div>
                  </div>
                  <div className="hidden">
                    <ul className="space-y-2 pt-8">
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.everyInStart}</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.unlimitUsers}</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.unlimitDocts}</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.unlimitParts}</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.digitalSignature}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Free */}
            <div className="rounded-[30px] md:rounded-[36px] bg-[#FAFAFA] overflow-hidden border-[1px] border-gray-200 p-8 relative lg:col-span-2">
              <div className="h-full">
                <div className="h-full z-10 relative lg:flex lg:justify-between lg:w-full lg:pr-8 lg:items-center">
                  <div className="flex flex-col flex-1 justify-between h-full space-y-5">
                    <div className="flex justify-between flex-col">
                      <div className="text-xl md:text-2xl font-bold text-gray-900 flex justify-between">
                        <span>{languages.free}</span>
                      </div>
                      <div className="pt-5 text-gray-500 font-medium text-base space-y-1">
                        <div className="flex items-center align-bottom">
                          <span className="pt-1.5">$</span>
                          <div className="ml-1 mr-2 text-2xl md:text-3xl font-bold text-gray-900">
                            <span>0</span>
                          </div>
                          <span className="pt-1.5">
                            {languages.perMonthUser}
                          </span>
                        </div>
                        <div className="text-base">
                          {paytType === "month"
                            ? languages.billedMon
                            : languages.billedAnn}
                        </div>
                      </div>
                      <div className="lg:hidden">
                        <ul className="space-y-2 pt-8 lg:pt-0">
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.noCredCardReq}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.fiftDaysFree}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-gray-600">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.fiftDocsMonth}</span>
                          </li>
                          {/* <li className="flex items-center font-medium space-x-2 text-gray-600">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.fiftSignDocsSatffMonth}</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-gray-600">
                            <ChekOpenSvg className="h-4 w-4" />
                            <span>{languages.fiftSignDocsPartMonth}</span>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="pt-2">
                      <WisePricingBtn onClickAction={onStartBill} label={languages.chooseFree} />
                    </div>
                  </div>
                  <div className="hidden lg:block">
                    <ul className="space-y-2 pt-8 lg:pt-0">
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.noCredCardReq}</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.fiftDaysFree}</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-gray-600">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.fiftDocsMonth}</span>
                      </li>
                      {/* <li className="flex items-center font-medium space-x-2 text-gray-600">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.fiftSignDocsSatffMonth}</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-gray-600">
                        <ChekOpenSvg className="h-4 w-4" />
                        <span>{languages.fiftSignDocsPartMonth}</span>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Enterprise */}
            <div className="rounded-[30px] md:rounded-[36px] bg-[#FAFAFA] overflow-hidden border-[1px] border-gray-200 p-8 relative sm:col-span-2 lg:col-span-1">
              <div className="h-full">
                <div className="flex flex-col justify-between h-full space-y-5">
                  <div className="flex justify-between flex-col">
                    <div className="text-xl md:text-2xl font-bold text-gray-900 flex justify-between">
                      <span>{languages.enterprise}</span>
                    </div>
                    <div className="pt-5">{languages.contactUCustomQte}</div>
                  </div>
                  <div className="pt-2">
                    <WisePricingBtn onClickAction={onStartBill} label={languages.contactSales} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreeMobileTools />
    </div>
  );
}

export default PricingPage;
