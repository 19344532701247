/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

import anyScannerImg from "src/assets/images/anyscanner_big.jpg";
import msLensImg from "src/assets/images/mocrosoft_lens.png";

const languages = getLang();
const appFxs = generalFxs();

const isEn = appFxs.getLocalLanguage() === "en";

export default function FreeMobileTools() {
  return (
    <div className="bg-[#495375] text-white">
      <div className="container mx-auto py-[60px]">
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-white text-[28px] font-semibold leading-[1.215]">
            {languages.free3RdPartyTools}
          </h3>
          <p
            data-eng={isEn}
            className="data-[eng=false]:hidden py-3 text-center text-[18px] text-slate-400 w-full max-w-[800px]"
          >
            Archivix is cloud-based and works in any browser so it's ready for
            teams working in one office or teams working from different
            locations. We're still expanding our mobile tools that can shape
            very well your business with document management. However, some
            third party mobile applications can help you scanning documents or
            converting images into PDF documents. They are free to use.
          </p>
          <p
            data-eng={isEn}
            className="data-[eng=true]:hidden py-3 text-center text-[18px] text-slate-400 w-full max-w-[800px]"
          >
            Archivix est basé sur le cloud et fonctionne dans n'importe quel
            navigateur, il est donc prêt pour les équipes travaillant dans un
            même bureau ou pour les équipes travaillant depuis différents
            endroits. Nous développons toujours nos outils mobiles qui peuvent
            très bien façonner votre entreprise grâce à la gestion documentaire.
            Cependant, certaines applications mobiles tierces peuvent vous aider
            à numériser des documents ou à convertir des images en documents
            PDF. Leur utilisation est gratuite.
          </p>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 py-6">
            <div className="flex flex-col items-center">
              <p className="py-3 text-center">
                <img
                  className="w-full max-w-[312px]"
                  src={anyScannerImg}
                  alt=""
                />
              </p>
              <h4 className="text-white text-2xl font-medium leading-[1.67] text-center">
                Any Scanner
              </h4>
              <p
                data-eng={isEn}
                className="data-[eng=false]:hidden text-white py-3 text-center w-full max-w-[450px]"
              >
                Any Scanner, a smart scanner app that turns your device into a
                portable PDF scanner, can easily convert paper documents and
                images to PDF/JPG in just one single tap.
              </p>
              <p
                data-eng={isEn}
                className="data-[eng=true]:hidden text-white py-3 text-center w-full max-w-[450px]"
              >
                Any Scanner, une application de scanner intelligente qui
                transforme votre appareil en un scanner PDF portable, peut
                facilement convertir des documents papier et des images au
                format PDF/JPG en un seul clic.
              </p>
              <p className="py-3">
                <a
                  className="cursor-pointer text-[#81caf3] hover:text-[#ff9d00] duration-200 ease-linear"
                  target="_blank"
                  href="https://apps.apple.com/us/app/anyscanner/id1658302219"
                  rel="noreferrer"
                >
                  {languages.applePlayStore}
                </a>
                &nbsp; &nbsp;
                <span className="text-[#FFFFFF99]">|</span>
                &nbsp; &nbsp;
                <a
                  className="cursor-pointer text-[#81caf3] hover:text-[#ff9d00] duration-200 ease-linear"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=pdf.scanner.scannerapp.free.pdfscanner"
                  rel="noreferrer"
                >
                  {languages.googlePlayStore}
                </a>
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 py-6">
            <div className="flex flex-col items-center">
              <p className="py-3 text-center">
                <img className="max-w-[312px]" src={msLensImg} alt="" />
              </p>
              <h4 className="text-white text-2xl font-medium leading-[1.67] text-center">
                Microsoft Lens
              </h4>
              <p
                data-eng={isEn}
                className="data-[eng=false]:hidden text-white py-3 text-center w-full max-w-[450px]"
              >
                Microsoft Lens is a powerful mobile scanner app that transforms
                your smartphone into a pocket-sized scanner. Capture documents,
                whiteboards, receipts, and more with ease.
              </p>
              <p
                data-eng={isEn}
                className="data-[eng=true]:hidden text-white py-3 text-center w-full max-w-[450px]"
              >
                Microsoft Lens est une puissante application de scanner mobile
                qui transforme votre smartphone en un scanner de poche. Capturez
                facilement des documents, des tableaux blancs, des reçus et bien
                plus encore.
              </p>
              <p className="py-3">
                <a
                  className="cursor-pointer text-[#81caf3] hover:text-[#ff9d00] duration-200 ease-linear"
                  target="_blank"
                  href="https://apps.apple.com/us/app/microsoft-lens-pdf-scanner/id975925059"
                  rel="noreferrer"
                >
                  {languages.applePlayStore}
                </a>
                &nbsp; &nbsp;
                <span className="text-[#FFFFFF99]">|</span>
                &nbsp; &nbsp;
                <a
                  className="cursor-pointer text-[#81caf3] hover:text-[#ff9d00] duration-200 ease-linear"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.microsoft.office.officelens"
                  rel="noreferrer"
                >
                  {languages.googlePlayStore}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
