/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

import logoColor from "src/assets/images/general/archivix_logo_small_black.png";
import { ReactComponent as UKFlagSvg } from "src/assets/images/svg/uk-flag.svg";
import { ReactComponent as FRFlagSvg } from "src/assets/images/svg/fr-flag.svg";

import appDecl from "src/utils/declarations";
import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

const languages = getLang();
const appFxs = generalFxs();



interface IWebSiteHeader {
  openCloseMenu: (bOpen: boolean) => void,
  lang: 'en'|'fr',
}

export default function WebSiteHeader({ openCloseMenu, lang }: IWebSiteHeader) {
  const FlagSvgIcon = lang === 'en' ? UKFlagSvg:FRFlagSvg;
    const updateLang = (oLng: string) => {
      appFxs.setLocalStorageByKey(appDecl.currentLang, oLng);
      document.location.reload();
    }

  return (
    <div className="fixed left-0 top-0 z-50 w-full bg-background/50 backdrop-blur-md">
      <header className="flex items-center justify-between mx-auto h-16 max-w-screen-xl px-4 md:h-20 lg:px-8">
        <div className="flex items-center space-x-4">
          <Link to="/">
            <img
              alt="Archivix Logo"
              loading="lazy"
              src={logoColor}
              className="w-[170px]"
            />
          </Link>
        </div>
        <div className="hidden items-center gap-x-6 md:flex">
          <Link
            to={"/support"}
            className="text-black hover:text-black/80 text-sm font-semibold"
          >
            {languages.support}
          </Link>
          <Link
            to={"/pricing"}
            className="text-black hover:text-black/80 text-sm font-semibold"
          >
            {languages.pricing}
          </Link>
          <Link
            to={"/blog"}
            className="text-black hover:text-black/80 text-sm font-semibold"
          >
            {languages.blog}
          </Link>
          <Link
            to={"/contactus"}
            className="text-black hover:text-black/80 text-sm font-semibold"
          >
            {languages.contactUs}
          </Link>
          <a
            target="_blank"
            className="text-black hover:text-black/80 text-sm font-semibold"
            href={`${appDecl.mainAppPath}/account/login`}
            rel="noreferrer"
          >
            {languages.signIn}
          </a>
          <div className="dropdown">
            <span
              className="dropdown-toggle not-dropdown cursor-pointer flex items-center gap-1 text-sm text-black hover:text-black/80"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FlagSvgIcon width={20} height={16} className="mt-1" />
              {lang==='en' ? languages.english_m: languages.french_m}
            </span>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item cursor-pointer"  onClick={() => updateLang('en')}>
                  <span className="flex items-center gap-1">
                    <UKFlagSvg width={20} height={16} />
                    <span>{languages.english_m}</span>
                  </span>
                </a>
              </li>
              <li>
                <a className="dropdown-item cursor-pointer" onClick={() => updateLang('fr')}>
                  <span className="flex items-center gap-1">
                    <FRFlagSvg width={20} height={16} />
                    <span>{languages.french_m}</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex md:hidden">
          <button
            className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground h-10 z-20 w-10 p-0"
            onClick={() => openCloseMenu(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-menu"
            >
              <line x1="4" x2="20" y1="12" y2="12"></line>
              <line x1="4" x2="20" y1="6" y2="6"></line>
              <line x1="4" x2="20" y1="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </header>
    </div>
  );
}
