import React, { useEffect } from "react";
import getLang from "src/utils/languages";
import { Link } from "react-router-dom";

const languages = getLang();

export default function TermsUsePage() {
  document.title = `${languages.termsOfUse} | Archivix`;
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <div className="flex flex-col legal">
      <div className="bg-white/40">
        <div className="container mx-auto">
          <div className="flex flex-col w-full max-w-3xl mx-auto">
            <h3 className="py-2 text-2xl font-bold">Terms of Use</h3>
            <p className="py-3 leading-[1.555] text-[18px]">
              These Terms of Use ("Terms") govern your access to and use of the
              services provided by [Your Company Name] ("Company", "we", "us",
              or "our"), including but not limited to our document management
              application and any related features, content, or services
              (collectively, the "Services"). By accessing or using the
              Services, you agree to be bound by these Terms. If you do not
              agree to these Terms, please do not access or use the Services.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              1. Acceptance of Terms
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              By accessing or using the Services, you affirm that you are of
              legal age to enter into these Terms and that you have read,
              understood, and agree to be bound by these Terms, our Privacy
              Policy, and any other policies or guidelines posted on our
              website.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">2. Use of Services</h3>

            <h4 className="py-1 text-lg font-bold">2.1 License</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              Subject to these Terms, we grant you a limited, non-exclusive,
              non-transferable, and revocable license to use the Services for
              your internal business purposes. You may not sublicense,
              distribute, or transfer any rights granted to you under these
              Terms.
            </p>

            <h4 className="py-1 text-lg font-bold">2.2 Compliance with Laws</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              You agree to use the Services in compliance with all applicable
              laws, regulations, and third-party agreements. You are solely
              responsible for ensuring that your use of the Services, including
              the use of electronic stamps and digital signatures, complies with
              all relevant legal requirements.
            </p>

            <h4 className="py-1 text-lg font-bold">2.3 User Accounts</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              You may be required to create an account to access certain
              features of the Services. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account. You agree to notify us immediately
              of any unauthorized use of your account or any other breach of
              security.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              3. Electronic Stamps and Digital Signatures
            </h3>
            <h4 className="py-1 text-lg font-bold">
              3.1 Use of Electronic Stamps
            </h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              Our Services may include features that allow you to apply
              electronic stamps to documents. You acknowledge and agree that the
              use of electronic stamps may have legal implications, and you are
              solely responsible for ensuring the legality and validity of any
              electronic stamps applied through the Services.
            </p>

            <h4 className="py-1 text-lg font-bold">
              3.2 Use of Digital Signatures
            </h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              Our Services may also include features that allow you and your
              staff or partners to use digital signatures to sign documents
              electronically. You acknowledge and agree that digital signatures
              have legal significance and that you are solely responsible for
              ensuring the legality and validity of any digital signatures
              applied through the Services.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              4. Intellectual Property
            </h3>

            <h4 className="py-1 text-lg font-bold">4.1 Ownership</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              All rights, title, and interest in and to the Services, including
              all intellectual property rights, are and will remain owned by
              WiseObjects Ltd. You may not use the WiseObjects Ltd's trademarks,
              logos, or other proprietary information without our prior written
              consent.
            </p>
            <h4 className="py-1 text-lg font-bold">4.2 User Content</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              You retain ownership of any content that you upload, submit, or
              otherwise make available through the Services ("User Content").
              WiseObjects Ltd has not right to use, reproduce, modify, adapt,
              publish, translate, distribute, and display such User Content for
              any purpose whatsoever.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">5. Termination</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              We reserve the right to suspend or terminate your access to the
              Services at any time and for any reason, without prior notice or
              liability. Upon termination, your right to use the Services will
              immediately cease, and you must cease all use of the Services and
              delete any downloaded or stored copies of the Services.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              6. Disclaimer of Warranties
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST
              EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES,
              EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              7. Limitation of Liability
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              IN NO EVENT WILL COMPANY, ITS AFFILIATES, LICENSORS, OR SERVICE
              PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL,
              SPECIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF
              PROFITS, DATA, USE, OR GOODWILL, ARISING OUT OF OR IN CONNECTION
              WITH YOUR USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED
              ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER
              LEGAL THEORY, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGES.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              8. Governing Law and Dispute Resolution
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              These Terms and any dispute arising out of or in connection with
              these Terms or the Services will be governed by and construed in
              accordance with the laws of Kigali, Rwanda juridiction, without
              regard to its conflict of law provisions. Any dispute arising out
              of or in connection with these Terms or the Services shall be
              resolved through arbitration in accordance with the rules of the
              Kigali, Rwanda juridiction, and the arbitration shall take place
              in Kigali, Rwanda.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">9. Changes to Terms</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              We reserve the right to modify or replace these Terms at any time.
              If we make material changes to these Terms, we will notify you by
              posting the updated Terms on our website or through other
              communication channels. Your continued use of the Services after
              any such changes constitutes your acceptance of the revised Terms.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">10. Contact Us</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              If you have any questions about these Terms or the Services,
              please contact us at <Link className="text-blue-500 hover:text-blue-900" to='/contactus'>Contact Us</Link> page.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
