import React, { useEffect } from "react";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

import bg2 from "src/assets/images/bg-insurance-1.jpg";
import insurBus1 from "src/assets/images/insurance_bus_1.jpg";
import insurBus2 from "src/assets/images/insurance_bus_2.jpg";
import insurBus3 from "src/assets/images/insurance_bus_3.jpg";
import insurBus4 from "src/assets/images/insurance_bus_4.jpg";
import insurBus5 from "src/assets/images/insurance_bus_5.jpg";

const languages = getLang();
const appFxs = generalFxs();

const locLang = appFxs.getLocalLanguage();

export default function InsuranceEfficPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.insurEffDoctMan} | Archivix`;
  }, []);

  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="relative bg-left bg-no-repeat bg-cover"
      >
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">
            {languages.insurEffDoctMan}
          </h3>
          <p className="w-full md:w-1/2 mt-4 text-xl text-white mb-4">
            {languages.insurEffDoctManMsg}
          </p>
        </div>
      </div>

      <div className="w-full">
        <div className="container mx-auto mb-6">
          <div className="flex flex-col py-12">
            {/* Centralized Document Repository */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 order-1">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Efficient Document Handling</>
                  ) : (
                    <>Gestion efficace des documents</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Insurance companies deal with a vast amount of paperwork,
                  including policy documents, claims forms, customer records,
                  and legal agreements. A document management application allows
                  for the digitization and centralization of these documents,
                  making them easily accessible to authorized employees. This
                  streamlines document handling processes, reduces the risk of
                  lost or misplaced documents, and improves overall efficiency.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Les compagnies d'assurance traitent une grande quantité de
                  paperasse, notamment des documents de police, des formulaires
                  de réclamation, des dossiers clients et des accords
                  juridiques. Une application de gestion documentaire permet de
                  numériser et de centraliser ces documents, les rendant
                  facilement accessibles aux collaborateurs autorisés. Cela
                  rationalise les processus de traitement des documents, réduit
                  le risque de perte ou d'égarement des documents et améliore
                  l'efficacité globale.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 order-2">
                <img
                  src={insurBus1}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>

            {/* Faster Claims Processing */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 md:order-2">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Faster Claims Processing</>
                  ) : (
                    <>Traitement plus rapide des sinistres</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Claims processing is a critical aspect of insurance
                  operations. Document management applications enable insurance
                  adjusters to quickly access relevant documents such as
                  policies, accident reports, and medical records. With
                  streamlined access to information, claims can be processed
                  more efficiently, leading to faster resolution and improved
                  customer satisfaction.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Le traitement des sinistres est un aspect essentiel des
                  opérations d'assurance. Les applications de gestion de
                  documents permettent aux experts en sinistres d'accéder
                  rapidement aux documents pertinents tels que les polices, les
                  rapports d'accident et les dossiers médicaux. Grâce à un accès
                  rationalisé aux informations, les sinistres peuvent être
                  traitées plus efficacement, ce qui entraîne une résolution
                  plus rapide et une meilleure satisfaction des clients.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 md:order-1">
                <img
                  src={insurBus2}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>

            {/* Enhanced Collaboration and Communication */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 order-1">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Enhanced Collaboration and Communication</>
                  ) : (
                    <>Collaboration et communication améliorées</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Insurance involves collaboration among various departments,
                  including underwriting, claims, and customer service. A
                  document management application facilitates seamless
                  collaboration by allowing employees to share and collaborate
                  on documents in real-time. Whether it's reviewing policy
                  documents or coordinating on complex claims, improved
                  communication leads to faster decision-making and better
                  outcomes for both the company and its customers.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  L'assurance implique une collaboration entre divers
                  départements, notamment la souscription, les sinistres et le
                  service client. Une application de gestion de documents
                  facilite une collaboration transparente en permettant aux
                  employés de partager et de collaborer sur des documents en
                  temps réel. Qu'il s'agisse de réviser des documents de police
                  ou de coordonner des réclamations complexes, une meilleure
                  communication conduit à une prise de décision plus rapide et à
                  de meilleurs résultats pour l'entreprise et ses clients.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 order-2">
                <img
                  src={insurBus3}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>

            {/* Improved Compliance and Security */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 md:order-2">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Improved Compliance and Security</>
                  ) : (
                    <>Conformité et sécurité améliorées</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Insurance companies must adhere to strict regulatory
                  requirements and data privacy laws. A document management
                  application helps ensure compliance by providing features such
                  as access controls, audit trails, and encryption. These
                  security measures safeguard sensitive customer information and
                  protect against data breaches, mitigating risks and
                  maintaining trust with policyholders.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Les compagnies d'assurance doivent respecter des exigences
                  réglementaires strictes et des lois sur la confidentialité des
                  données. Une application de gestion de documents contribue à
                  garantir la conformité en fournissant des fonctionnalités
                  telles que les contrôles d'accès, les pistes d'audit et le
                  cryptage. Ces mesures de sécurité protègent les informations
                  sensibles des clients et protègent contre les violations de
                  données, atténuant les risques et maintenant la confiance avec
                  les assurés.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 md:order-1">
                <img
                  src={insurBus4}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>

            {/* Cost Savings and Sustainability */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 order-1">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Cost Savings and Sustainability</>
                  ) : (
                    <>Économies de coûts et durabilité</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  By reducing reliance on paper-based processes and optimizing
                  document workflows, insurance companies can realize cost
                  savings in terms of paper, printing, storage, and
                  administrative overhead. Additionally, transitioning to
                  digital document management promotes sustainability by
                  reducing environmental impact and supporting green
                  initiatives.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  En réduisant le recours aux processus papier et en optimisant
                  les flux de travail documentaire, les compagnies d'assurance
                  peuvent réaliser des économies en termes de papier,
                  d'impression, de stockage et de frais administratifs. De plus,
                  la transition vers la gestion numérique des documents favorise
                  la durabilité en réduisant l'impact environnemental et en
                  soutenant les initiatives vertes.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 order-2">
                <img
                  src={insurBus5}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>
          </div>

          <div className="w-full md:w-[60%] mr-2 mx-auto">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <p
                data-lang={locLang}
                className="data-[lang='fr']:hidden text-gray-700"
              >
                Overall, a document management application empowers insurance
                companies to streamline operations, improve customer service,
                mitigate risks, and drive overall business efficiency in a
                highly competitive industry.
              </p>
              <p
                data-lang={locLang}
                className="data-[lang='en']:hidden text-gray-700"
              >
                Dans l'ensemble, une application de gestion documentaire permet
                aux compagnies d'assurance de rationaliser leurs opérations,
                d'améliorer le service client, d'atténuer les risques et
                d'améliorer l'efficacité globale de leur entreprise dans un
                secteur hautement concurrentiel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
