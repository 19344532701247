/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import WebMobileMenu from "src/components/WebMobileMenu";
import WebSiteFooter from "src/components/WebSiteFooter";
import WebSiteHeader from "src/components/WebSiteHeader";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

const languages = getLang();
const appFxs = generalFxs();

const isEn = appFxs.getLocalLanguage() === 'en';

function MainPageLayout() {
  document.title = `${languages.loading} | Archivix`;

  const [mobileMenuVis, setMobileMenuVis] = useState(false);
  const navigate = useNavigate();
  //const year = new Date().getFullYear();

  const onMenuclick = (goTo: string) => {
    setMobileMenuVis(false);
    navigate(goTo);
  };

  return (
    <>
      <div className="relative flex min-h-[100vh] flex-col pt-20 md:pt-28 overflow-y-auto overflow-x-hidden">
        {/* Header */}
        <WebSiteHeader openCloseMenu={setMobileMenuVis} lang={isEn ? 'en': 'fr'} />
        {/*  */}
        <Outlet />
        <WebSiteFooter />
      </div>

      <WebMobileMenu
        onMenuClick={onMenuclick}
        visible={mobileMenuVis}
        setMobileMenuVis={setMobileMenuVis}
      />
    </>
  );
}

export default MainPageLayout;
