import React, { useEffect } from "react";
import getLang from "src/utils/languages";
import { Link } from "react-router-dom";

const languages = getLang();

export default function PrivacyPolicyPage() {
  document.title = `${languages.privPolicy} | Archivix`;
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <div className="flex flex-col legal">
      <div className="bg-white/40">
        <div className="container mx-auto">
          <div className="flex flex-col w-full max-w-3xl mx-auto">
            <h3 className="py-2 text-2xl font-bold">Privacy Policy</h3>
            <p className="py-2">Last updated: [12th April, 2024]</p>
            <p className="py-3 leading-[1.555] text-[18px]">
              <strong>WiseObjects Ltd</strong> ("Company", "we", "us", or "our")
              is committed to protecting the privacy of your information. This
              Privacy Policy explains how we collect, use, and disclose
              information when you use our document management application and
              related services (collectively, the <strong>"Services"</strong>).
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              1. Information We Collect
            </h3>

            <h4 className="py-1 text-lg font-bold">1.1 Account Information</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              When you register for an account with our Services, we collect
              certain information, such as your name, email address, and company
              details. This information is necessary for the creation and
              management of your account.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              1. Information We Collect
            </h3>

            <h4 className="py-1 text-lg font-bold">1.2 User Data</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              We may collect information about how you and your authorized users
              interact with our Services, including but not limited to usage
              data, access logs, and device information. This information helps
              us improve the performance and usability of our Services.
            </p>

            <h4 className="py-1 text-lg font-bold">1.3 Invitations</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              When you invite staff or partners to use our Services, we collect
              their email addresses for the purpose of sending invitation emails
              and managing user accounts. We do not collect any additional
              personal information from invitees unless provided voluntarily.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              2. Use of Information
            </h3>

            <h4 className="py-1 text-lg font-bold">
              2.1 Providing and Improving the Services
            </h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              We use the information we collect to provide, maintain, and
              improve our Services, including but not limited to:
              <br />
              <br />
              <ul>
                <li>Authenticating users and managing user accounts</li>
                <li>
                  Enabling the use of electronic stamps and digital signatures
                </li>
                <li>
                  Analyzing usage patterns and trends to enhance the user
                  experience
                </li>
              </ul>
            </p>
            <h4 className="py-1 text-lg font-bold">2.2 Communication</h4>
            <p className="py-3 leading-[1.555] text-[16px]">
              We may use your email address to send you important information
              about your account, updates to our Services, or other relevant
              communications. You can opt out of receiving non-essential
              communications at any time by adjusting your email preferences.
            </p>

            <h3 className="mt-2 py-1 text-xl font-bold">
              3. Disclosure of Information
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              We do not sell, trade, or otherwise transfer your information to
              third parties without your consent, except as described in this
              Privacy Policy or as required by law. We may share your
              information with trusted third-party service providers who assist
              us in operating our Services, conducting our business, or
              servicing you, as long as those parties agree to keep this
              information confidential.
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">4. Data Retention</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              We retain your information for as long as necessary to fulfill the
              purposes outlined in this Privacy Policy unless a longer retention
              period is required or permitted by law. When your information is
              no longer needed, we will securely delete or anonymize it.
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">5. Security</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              We take reasonable measures to protect the confidentiality,
              integrity, and security of your information. However, no method of
              transmission over the internet or electronic storage is completely
              secure, so we cannot guarantee absolute security.
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">
              6. Children's Privacy
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              Our Services are not intended for children under the age of 13,
              and we do not knowingly collect personal information from children
              under the age of 13. If we become aware that we have collected
              personal information from a child under the age of 13, we will
              take steps to delete such information as soon as possible.
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">
              7. Changes to this Privacy Policy
            </h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              We reserve the right to update or change this Privacy Policy at
              any time. We will notify you of any changes by posting the new
              Privacy Policy on this page. You are advised to review this
              Privacy Policy periodically for any changes.
            </p>
            <h3 className="mt-2 py-1 text-xl font-bold">8. Contact Us</h3>
            <p className="py-3 leading-[1.555] text-[16px]">
              If you have any questions about this Privacy Policy or our data
              practices, please contact us at&nbsp;
              <Link
                className="text-blue-500 hover:text-blue-700"
                to="/contactus"
              >
                Contact Us
              </Link>&nbsp;
              page.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
