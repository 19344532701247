/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import bg1 from "src/assets/images/home_page_img.png";
import { Link, useNavigate } from "react-router-dom";
import FreeMobileTools from "src/components/FreeMobileTools";
import { SmallFeatureComp } from "src/components/SmallFeatureComp";
import CustomerSpecComp, {
  ArchivixLinkSoluLearn,
  DoctManagtExplain,
} from "src/components/CustomerSpecComp";

const languages = getLang();
const appFxs = generalFxs();

const localLng = appFxs.getLocalLanguage();

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.home} | Archivix`;
  }, []);
  return (
    <div className="flex flex-col">
      <div
        className="bg-cover bg-no-repeat bg-right relative"
        style={{
          backgroundImage: `url(${bg1})`,
        }}
      >
        <div className="absolute w-full h-full z-0 bg-gradient-to-r from-white"></div>
        <div className="container mx-auto pt-10 pb-[60px] relative z-[1]">
          <div className="flex flex-wrap">
            <div className="w-full md:w-[50%] flex flex-col space-y-4">
              <h3 className="text-[2.2em]  md:text-[2.7em] leading-[1.2]">
                <span className="text-[#00adff]">Archivix</span>:&nbsp;
                {languages.featureTitle}
                <br />
                <span className="text-[#303ab2]">{languages.easyBus}</span>
              </h3>
              <p className="mt-4 md:text-lg">{languages.featureTitle2}</p>
              <h2 className="text-[1.4em] text-[#495375]">
                {languages.features}
              </h2>
              <div className="banner-cta">
                <div className="banner-cta_slide">&nbsp;</div>
                <Link to="/">{languages.doctOrg}</Link>
              </div>
              <div className="banner-cta">
                <div className="banner-cta_slide">&nbsp;</div>
                <Link to="/">{languages.doctCollab}</Link>
              </div>
              <div className="banner-cta">
                <div className="banner-cta_slide">&nbsp;</div>
                <Link to="/">{languages.doctSec}</Link>
              </div>
            </div>
            <div className="w-full md:w-[50%]"></div>
          </div>
        </div>
      </div>

      {/* Schools */}
      <SmallFeatureComp onNavig={(pPath) => navigate(pPath)} />

      {/* purpose and customers */}
      <div className="bg-white">
        <div className="container mx-auto py-[60px]">
          <div className="flex flex-wrap">
            <div className="w-full md:w-[40%] mb-5 md:border-r md:border-blue-300 md:pr-9">
              <DoctManagtExplain lang={localLng} />
            </div>

            <div className="w-full md:w-[60%] mb-5">
              <CustomerSpecComp />
            </div>

            <div className="py-5 flex flex-col space-y-3 w-full max-w-[750px] mx-auto">
              <h2 className="text-[#495375] text-[34px] leadin-[1.22] text-center">
              {localLng === "en" ? <>A place dedicated to your documents</>:<>Un espace dédié à vos documents</>}
              </h2>
              <p className="text-[21px] my-[10px] text-center">
                {localLng === "en" ? (
                  <>
                    Document management and signature workflow automation
                    solution for business hat need security when dealing with
                    documents - from any device, any time, anywhere.
                  </>
                ) : (
                  <>
                    Solution de gestion de documents et d'automatisation du flux
                    de signatures pour les entreprises qui ont besoin de
                    sécurité lorsqu'elles traitent des documents - depuis
                    n'importe quel appareil, à tout moment et en tout lieu.
                  </>
                )}
              </p>
            </div>

            <ArchivixLinkSoluLearn lang={localLng} />
          </div>
        </div>
      </div>

      <FreeMobileTools />
    </div>
  );
}

export default HomePage;
