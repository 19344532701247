import { ReactComponent as ArrowBtnRightSvg } from "src/assets/images/svg/arrow-bt-right.svg";

interface IWiseActionButton {
  label: string;
  handleClick?: () => void;
}

export const WiseActionButton = ({ label, handleClick }: IWiseActionButton) => {
  const onHadleclick = () => {
    if (!handleClick) return;
    handleClick!();
  };
  return (
    <button
      onClick={onHadleclick}
      className="inline-block px-4 py-2 border border-white rounded-3xl bg-[#ff9d00] text-white hover:bg-[#ff9d00]/90 hover:text-[#ff9d00] hover:border-[#ff9d00] transition-all"
    >
      {label}
    </button>
  );
};

export const WiseActionButtonV2 = ({
  label,
  handleClick,
}: IWiseActionButton) => {
  const onHadleclick = () => {
    if (!handleClick) return;
    handleClick!();
  };

  return (
    <button
      onClick={onHadleclick}
      className="inline-block px-4 py-2 border border-white rounded-[5px] bg-[#FFFFFF4D] text-white hover:bg-[#FFFFFF4D]/20 hover:text-white hover:border-white transition-all"
    >
      {label}
    </button>
  );
};
interface IWisePricingBtn {
  label: string;
  onClickAction: () => void;
}
export const WisePricingBtn = ({ label, onClickAction }: IWisePricingBtn) => {
  return (
    <button
      type="button"
      onClick={onClickAction}
      className="appearance-none inline-flex hover:shadow-2xl transition-all duration-300 hover:scale-105 items-center group space-x-1.5 bg-black text-white py-3 px-4 rounded-2xl cursor-pointer"
    >
      <span className="w-full font-semibold text-base">{label}</span>
      <ArrowBtnRightSvg />
    </button>
  );
};
