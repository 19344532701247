/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { WiseActionButton, WiseActionButtonV2 } from "./button_components";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

const languages = getLang();
const appFxs = generalFxs();

interface ISmallFeatureComp {
  onNavig: (navigPath: string) => void;
}

export const SmallFeatureComp = ({ onNavig }: ISmallFeatureComp) => {
  //const oPost = appFxs.randomIntFromInterval(1,2);

  const [oPost, setOPost] = useState(appFxs.randomIntFromInterval(1, 3));

  return (
    <div className="bg-[#303ab2] text-white">
      <div className="container mx-auto py-[60px]">
        <div className="flex flex-col space-y-3">
          <div className="flex items-center">
            <a
              data-show={oPost}
              className="cursor-pointer text-gray-400 hover:text-white data-[show='1']:text-white"
              onClick={() => setOPost(1)}
            >
              {languages.schools}
            </a>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <a
              data-show={oPost}
              className="cursor-pointer text-gray-400 hover:text-white data-[show='2']:text-white"
              onClick={() => setOPost(2)}
            >
              {languages.insurance}
            </a>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <a
              data-show={oPost}
              className="cursor-pointer text-gray-400 hover:text-white data-[show='3']:text-white"
              onClick={() => setOPost(3)}
            >
              {languages.individuals}
            </a>
          </div>

          <div className="flex flex-wrap">
            {/* features */}
            <div className="w-full md:w-[50%] mb-5">
              {/* schools */}
              <div
                data-show={oPost}
                className="hidden data-[show='1']:flex flex-col space-y-3"
              >
                <h1 className="text-[28px] font-semibold leading-[1.215]">
                  {languages.howSchoolArchEff}
                </h1>
                <p>{languages.howSchoolArchEffMsg}</p>
                <p className="mt-4">
                  <WiseActionButton
                    label={languages.moreDetails}
                    handleClick={() => onNavig("/features/schools")}
                  />
                </p>
              </div>
              {/* insurance */}
              <div
                data-show={oPost}
                className="hidden data-[show='2']:flex flex-col space-y-3"
              >
                <h1 className="text-[28px] font-semibold leading-[1.215]">
                  {languages.insurEffDoctMan}
                </h1>
                <p>{languages.insurEffDoctManMsg}</p>
                <p className="mt-4">
                  <WiseActionButton
                    label={languages.moreDetails}
                    handleClick={() => onNavig("/features/insurance")}
                  />
                </p>
              </div>              
              {/* individuals */}
              <div
                data-show={oPost}
                className="hidden data-[show='3']:flex flex-col space-y-3"
              >
                <h1 className="text-[28px] font-semibold leading-[1.215]">
                  {languages.indivEffDoctMan}
                </h1>
                <p>{languages.indivEffDoctManMsg}</p>
                <p className="mt-4">
                  <WiseActionButton
                    label={languages.moreDetails}
                    handleClick={() => onNavig("/features/individuals")}
                  />
                </p>
              </div>
            </div>
            {/* webinars */}
            <div className="w-full md:w-[50%] mb-5">
              <div className="flex flex-col space-y-3 md:px-12">
                <h1 className="text-[28px] font-semibold leading-[1.215] text-white/80">
                  {languages.freeWeSeries}
                </h1>
                <p className="text-white/80">{languages.freeWeSeriesMSg}</p>
                <p>
                  <WiseActionButtonV2
                    label={languages.viewAllWebin}
                    handleClick={() => onNavig("/blog")}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
