import React, { useEffect } from "react";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

import bg2 from "src/assets/images/bg-individuals-1.jpg";

import insurBus1 from "src/assets/images/individuals_bus_1.jpg";
import insurBus2 from "src/assets/images/individuals_bus_2.jpg";
import insurBus3 from "src/assets/images/individuals_bus_3.jpg";
import insurBus4 from "src/assets/images/individuals_bus_4.jpg";
import insurBus5 from "src/assets/images/individuals_bus_5.jpg";

const languages = getLang();
const appFxs = generalFxs();

const locLang = appFxs.getLocalLanguage();

export default function PersonalEffPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.indivEffDoctMan} | Archivix`;
  }, []);

  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="relative bg-left bg-no-repeat bg-cover"
      >
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">
            {languages.indivEffDoctMan}
          </h3>
          <p className="w-full md:w-1/2 mt-4 text-xl text-white mb-4">
            {languages.indivEffDoctManMsg}
          </p>
        </div>
      </div>

      <div className="w-full">
        <div className="container mx-auto mb-6">
          <div className="flex flex-col py-12">
            <p className="text-xl w-full max-w-2xl mb-4">
              {languages.individualsFPar}
            </p>

            {/* Organization */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 order-1">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? <>Organization</> : <>Organisation</>}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Individuals often deal with a multitude of documents,
                  including bills, receipts, contracts, and personal records. A
                  document management application provides a centralized
                  platform to organize and categorize these documents digitally,
                  making them easily searchable and accessible whenever needed.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Les individus traitent souvent une multitude de documents,
                  notamment des factures, des reçus, des contrats et des
                  dossiers personnels. Une application de gestion de documents
                  fournit une plate-forme centralisée pour organiser et classer
                  ces documents numériquement, les rendant facilement
                  consultables et accessibles en cas de besoin.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 order-2">
                <img
                  src={insurBus1}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>

            {/* Access Anywhere, Anytime */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 md:order-2">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Access Anywhere, Anytime</>
                  ) : (
                    <>Accédez partout et à tout moment</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  With a document management application, individuals can access
                  their documents from any device with an internet connection.
                  Whether they're at home, at work, or on the go, they can
                  quickly retrieve important documents without the need for
                  physical storage or carrying bulky files.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Avec une application de gestion de documents, les particuliers
                  peuvent accéder à leurs documents depuis n’importe quel
                  appareil doté d’une connexion Internet. Qu'ils soient à la
                  maison, au travail ou en déplacement, ils peuvent récupérer
                  rapidement des documents importants sans avoir besoin de les
                  stocker physiquement ni de transporter des fichiers
                  volumineux.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 md:order-1">
                <img
                  src={insurBus2}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>

            {/* Collaboration and Sharing */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 order-">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Collaboration and Sharing</>
                  ) : (
                    <>Collaboration et partage</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Individuals may need to collaborate with others on projects or
                  share documents with family, friends, or colleagues. A
                  document management application facilitates seamless
                  collaboration by allowing users to share documents securely,
                  track changes, and collaborate in real-time, enhancing
                  productivity and teamwork.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Les individus peuvent avoir besoin de collaborer avec d'autres
                  sur des projets ou de partager des documents avec leur
                  famille, leurs amis ou leurs collègues. Une application de
                  gestion de documents facilite une collaboration transparente
                  en permettant aux utilisateurs de partager des documents en
                  toute sécurité, de suivre les modifications et de collaborer
                  en temps réel, améliorant ainsi la productivité et le travail
                  d'équipe.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 order-2">
                <img
                  src={insurBus3}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>

            {/* Security and Privacy */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 md:order-2">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Security and Privacy</>
                  ) : (
                    <>Sécurité et confidentialité</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Personal documents often contain sensitive information that
                  needs to be protected. A document management application
                  offers security features such as encryption, access controls,
                  and password protection to ensure the confidentiality and
                  integrity of personal data, providing peace of mind to users.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Les documents personnels contiennent souvent des informations
                  sensibles qui doivent être protégées. Une application de
                  gestion de documents offre des fonctionnalités de sécurité
                  telles que le cryptage, les contrôles d'accès et la protection
                  par mot de passe pour garantir la confidentialité et
                  l'intégrité des données personnelles, offrant ainsi une
                  tranquillité d'esprit aux utilisateurs.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 md:order-1">
                <img
                  src={insurBus4}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>

            {/* Security and Privacy */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 order-1">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Task Automation</>
                  ) : (
                    <>Automatisation des tâches</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Document management applications can automate repetitive tasks such as scanning, filing, and organizing documents, saving users time and effort. Features like optical character recognition (OCR) can also extract text from scanned documents, making them searchable and editable.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Les applications de gestion de documents peuvent automatiser les tâches répétitives telles que la numérisation, le classement et l'organisation des documents, économisant ainsi du temps et des efforts aux utilisateurs. Des fonctionnalités telles que la reconnaissance optique de caractères (OCR) peuvent également extraire le texte des documents numérisés, les rendant ainsi consultables et modifiables.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 order-2">
                <img
                  src={insurBus5}
                  alt=""
                  className="w-full aspect-video rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
