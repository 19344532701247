import React, { useEffect } from "react";

import getLang from "src/utils/languages";

import bg2 from "src/assets/images/bg-support.jpg";
import { ReactComponent as SupportPhoneSvg } from "src/assets/images/svg/phone_support_color.svg";
import { ReactComponent as SupportEmailSvg } from "src/assets/images/svg/email_support_color.svg";
import { ReactComponent as KnowledgeEmailSvg } from "src/assets/images/svg/knowledge_support_color.svg";

import appDecl from "src/utils/declarations";
import generalFxs from "src/utils/general_fx";
import { Link } from "react-router-dom";

const appFxs = generalFxs();
const languages = getLang();

const loclang = appFxs.getLocalLanguage();

function SupportPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.support} | Archivix`;
  }, []);

  return (
    <div className="flex flex-col">
      <div style={{ backgroundImage: `url(${bg2})` }} className="relative  bg-no-repeat bg-cover">
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">{languages.support}</h3>
          <p
            data-lang={loclang}
            className="data-[lang='fr']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Welcome to the support page for Archivix! We're here to help you
            make the most of our platform and address any issues or questions
            you may have.
          </p>
          <p
            data-lang={loclang}
            className="data-[lang='en']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Bienvenue sur la page d'assistance d'Archivix! Nous sommes là pour
            vous aider à tirer le meilleur parti de notre plateforme et à
            répondre à tous les problèmes ou questions que vous pourriez avoir.
          </p>
        </div>
      </div>

      <div className="container mx-auto py-8">
        <div className="flex flex-wrap">
          <div className="w-full md:w-[48%] lg:w-[32%] mr-2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <SupportEmailSvg className="mx-auto mb-4 w-20 h-20" />
              <h2 className="text-xl font-semibold mb-2">
                {languages.emailSupport}
              </h2>
              <p
                data-lang={loclang}
                className="data-[lang='fr']:hidden text-gray-700"
              >
                For general inquiries, technical assistance, or any other
                support-related questions, please email us at&nbsp;
                <a
                  href="mailto:support@wiseobjects.com"
                  className="text-blue-500"
                >
                  support@wiseobjects.com
                </a>
                .
              </p>
              <p
                data-lang={loclang}
                className="data-[lang='en']:hidden text-gray-700"
              >
                Pour des demandes générales, une assistance technique ou tout
                autre questions liées à l'assistance, veuillez nous envoyer un
                e-mail&nbsp;
                <a
                  href="mailto:support@wiseobjects.com"
                  className="text-blue-500"
                >
                  support@wiseobjects.com
                </a>
                .
              </p>
            </div>
          </div>

          <div className="w-full md:w-[48%] lg:w-[32%] mr-2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <SupportPhoneSvg className="mx-auto mb-4 w-20 h-20" />
              <h2 className="text-xl font-semibold mb-2">
                {languages.phoneSupport}
              </h2>
              <p
                data-lang={loclang}
                className="data-[lang='fr']:hidden text-gray-700"
              >
                If you require immediate assistance or prefer to speak with a
                support representative over the phone, you can reach us at&nbsp;
                <a href="tel:250788307310" className="text-blue-500">
                  250 788 307 310
                </a>
                .
              </p>
              <p
                data-lang={loclang}
                className="data-[lang='en']:hidden text-gray-700"
              >
                Si vous avez besoin d'une assistance immédiate ou si vous
                préférez parler à un représentant de l'assistance par téléphone,
                vous pouvez nous joindre au&nbsp;
                <a href="tel:250788307310" className="text-blue-500">
                  +250 788 307 310
                </a>
                .
              </p>
            </div>
          </div>

          <div className="w-full md:w-[48%] lg:w-[32%] mr-2">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <KnowledgeEmailSvg className="mx-auto mb-4 w-20 h-20" />
              <h2 className="text-xl font-semibold mb-2">
                {languages.knowledgeBase}
              </h2>
              <p
                data-lang={loclang}
                className="data-[lang='fr']:hidden text-gray-700"
              >
                Explore our knowledge base for helpful articles, guides, and
                tutorials on using our Document Management application.
              </p>
              <p
                data-lang={loclang}
                className="data-[lang='en']:hidden text-gray-700"
              >
                Explorez notre base de connaissances pour des articles utiles,
                des guides et des tutoriels sur l'utilisation de notre
                application de gestion de documents.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        data-lang={loclang}
        className="hidden data-[lang='en']:block container mx-auto py-8"
      >
        <div className="flex flex-col w-full max-w-[650px] mx-auto">
          <h2 className="text-xl py-2 font-bold">
            Frequently Asked Questions (FAQs)
          </h2>
          <h4 className="text-lg font-semibold mb-3">
            How do I get started with the Document Management application?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            To get started with our application, simply&nbsp;
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`${appDecl.registerPath}`}
            >
              sign up for an account
            </Link>
            on our website. Once registered, you can start uploading and
            managing your documents right away.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Can I customize the Document Management application to suit my
            company's needs?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Yes, our application offers various customization options to tailor
            the platform to your specific requirements. Please contact our
            <Link className="text-blue-600 hover:text-blue-900" to="/contactus">
              support team
            </Link>&nbsp;
            for assistance with customization and configuration.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Is training available for using the Document Management application?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Yes, we provide training sessions and resources to help you and your
            team get up to speed with using our application efficiently. Please
            reach out to our&nbsp;
            <Link className="text-blue-600 hover:text-blue-900" to="/contactus">
              support team
            </Link>&nbsp;
            to schedule a training session or access our training materials.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            How secure is the Document Management application?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            We take security seriously and implement robust measures to
            safeguard your data. Our application uses encryption, access
            controls, and other security features to protect your documents and
            information. For more information about our security practices,
            please refer to our&nbsp;
            <Link
              className="text-blue-600 hover:text-blue-900"
              to="/leg/privacy"
            >
              online form
            </Link>
            .
          </p>

          <h2 className="text-xl py-2 font-bold">Feedback</h2>
          <p className="text-[16px] text-gray-700 mb-4">
            We value your feedback and suggestions for improving our Document
            Management application. If you have any ideas, feature requests, or
            comments, please submit your feedback through our&nbsp;
            <Link className="text-blue-600 hover:text-blue-900" to="/contacus">
              online form
            </Link>
            . Your input helps us enhance our platform and better serve our
            users.
          </p>
        </div>
      </div>

      <div
        data-lang={loclang}
        className="hidden data-[lang='fr']:block container mx-auto py-8"
      >
        <div className="flex flex-col w-full max-w-[650px] mx-auto">
          <h2 className="text-xl py-2 font-bold">
            Questions fréquemment posées (FAQs)
          </h2>
          <h4 className="text-lg font-semibold mb-3">
            Comment puis-je démarrer avec l'application de gestion de
            documents?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Pour démarrer avec notre application, créez simplement 
            <Link
              className="text-blue-600 hover:text-blue-900"
              to={`${appDecl.registerPath}`}
            >
              un compte sur
            </Link>
            votre site Web. Une fois inscrit, vous pouvez commencer à
            télécharger et à gérer vos documents immédiatement.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Puis-je personnaliser l'application de gestion documentaire pour
            l'adapter aux besoins de mon entreprise?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Oui, notre application propose diverses options de personnalisation
            pour adapter la plateforme à vos besoins spécifiques. Veuillez
            contacter notre 
            the platform to your specific requirements. Please contact our
            <Link className="text-blue-600 hover:text-blue-900" to="/contactus">
            équipe d'assistance
            </Link>
             pour obtenir de l'aide sur la
            personnalisation et la configuration.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Une formation est-elle disponible pour utiliser l'application de
            gestion documentaire?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Oui, nous proposons des sessions de formation et des ressources pour
            vous aider, vous et votre équipe, à vous familiariser avec
            l'utilisation efficace de notre application. Veuillez contacter
            notre <Link className="text-blue-600 hover:text-blue-900" to="/contactus">
            équipe d'assistance
            </Link>
             pour planifier une session de formation ou
            accéder à nos supports de formation.
          </p>

          <h4 className="text-lg font-semibold mb-3">
            Dans quelle mesure l'application de gestion de documents est-elle
            sécurisée?
          </h4>
          <p className="text-[16px] text-gray-700 mb-4">
            Nous prenons la sécurité au sérieux et mettons en œuvre des mesures
            robustes pour protéger vos données. Notre application utilise le
            cryptage, les contrôles d'accès et d'autres fonctionnalités de
            sécurité pour protéger vos documents et informations. Pour plus
            d'informations sur nos pratiques de sécurité, veuillez vous référer
            à notre formulaire en ligne.
          </p>

          <h2 className="text-xl py-2 font-bold">Retour d'information</h2>
          <p className="text-[16px] text-gray-700 mb-4">
            Nous apprécions vos commentaires et suggestions pour améliorer notre
            application de gestion de documents. Si vous avez des idées, des
            demandes de fonctionnalités ou des commentaires, veuillez soumettre
            vos commentaires via 
            <Link
              className="text-blue-600 hover:text-blue-900"
              to="/leg/privacy"
            >
              notre formulaire
            </Link>

 en ligne. Votre contribution
            nous aide à améliorer notre plateforme et à mieux servir nos
            utilisateurs.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SupportPage;
