interface IArchFormInput {
  type: React.HTMLInputTypeAttribute | "textarea";
  name: string;
  value: string | number;
  rows?: number;
  placeholder?: string;
  errorText?: string;
  isBusy?: boolean;
  disabled?: boolean;
  updateFormFld?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
}

export const ArchFormInput = ({
  isBusy,
  disabled,
  errorText,
  type,
  placeholder,
  name,
  value,
  rows,
  updateFormFld,
}: IArchFormInput) => {
  const bBusy = isBusy ?? false;
  const bDisabled = disabled ?? false;
  const nRows = rows ?? 3;

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (!updateFormFld) return;
    updateFormFld(e);
  };
  return (
    <div data-haserror={errorText !== undefined}
     className="data-[haserror=true]:mb-2 mb-9 flex flex-col space-y-1 w-full">
      {type !== "textarea" && (
        <input
        data-haserror={errorText !== undefined}
        className="data-[haserror=true]:text-red-600 data-[haserror=true]:border-red-400 shadow appearance-none border-solid border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        disabled={bBusy || bDisabled}
      />)}
      {type === "textarea" && (
        <textarea
          id={name}
          data-haserror={errorText !== undefined}
          className="resize-none data-[haserror=true]:text-red-600 data-[haserror=true]:border-red-400 min-h-0 appearance-none border-2 rounded h-64 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}
          rows={nRows}
          disabled={bBusy || bDisabled}
        ></textarea>
      )}
      {errorText && (
        <div className="block text-red-600 text-xs py-0">
          {errorText}
        </div>
      )}
    </div>
  );
};
