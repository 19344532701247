import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";


import MainPageLayout from './pages/_layout';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import PricingPage from './pages/PricingPage';
import SupportPage from './pages/SupportPage';
import TermsUsePage from './pages/legal/TermsUsePage';
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import CookiesPolicyPage from './pages/legal/CookiesPolicyPage';
import ContactUsPage from './pages/ContactUsPage';
import FeaturesSchoolPage from './pages/features/FeaturesSchoolPage';
import InsuranceEfficPage from './pages/features/InsuranceEfficPage';
import IndividualsEfficPage from './pages/features/IndividualsEfficPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainPageLayout />}>
          <Route index element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="support" element={<SupportPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="contactus" element={<ContactUsPage />} />

          <Route path="features/schools" element={<FeaturesSchoolPage />} />
          <Route path="features/insurance" element={<InsuranceEfficPage />} />
          <Route path="features/individuals" element={<IndividualsEfficPage />} />

          <Route path="legal/terms" element={<TermsUsePage />} />
          <Route path="legal/privacy" element={<PrivacyPolicyPage />} />
          <Route path="legal/cookies" element={<CookiesPolicyPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
