/* eslint-disable @typescript-eslint/no-explicit-any */
import Swal from "sweetalert2";
import appDecl from "./declarations";
import GetLanguage from "./languages";

import moment from "moment";
//import jquery from 'jquery';
import loadingAjax from "src/assets/images/loading.png";
import { DateOperation, HeaderType } from "src/models/general_types";

function generalFx() {
  const language = GetLanguage();
  const wdMonthsLng = [
    language.jan_lng,
    language.feb_lng,
    language.mar_lng,
    language.apr_lng,
    language.may_lng,
    language.jun_lng,
    language.jul_lng,
    language.aug_lng,
    language.sept_lng,
    language.oct_lng,
    language.nov_lng,
    language.dec_lng,
  ];
  const wdMonthsShrt = [
    language.jan_shrt,
    language.feb_shrt,
    language.mar_shrt,
    language.apr_lng,
    language.may_lng,
    language.jun_lng,
    language.jul_shrt,
    language.aug_shrt,
    language.sept_shrt,
    language.oct_shrt,
    language.nov_shrt,
    language.dec_shrt,
  ];
  const getLocalStorageByKey = (key: string, defaultValue: string) => {
    const elt = localStorage.getItem(key);
    return elt ?? defaultValue;
  };
  const setLocalStorage = (key: string, valueData: string) => {
    localStorage.setItem(key, valueData);
  };
  const generateUUID = (): string => {
    let d = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  const openWindowUrlTab = (url: string) => {
    window.open(url, '_blank');
  };
  const openWindowUrl = (url: string, width?: number, height?: number) => {
    const fW = width ?? 320;
    const fH = height ?? 320;

    const windowFeatures = `left=100,top=100,width=${fW},height=${fH}`;
    window.open(url, "mozillaWindow", windowFeatures);
  };

  return {
    //#region general
    openWindowUrlTab,
    openWindowUrl,
    getLocalStorageByKey,
    setLocalStorage,
    setLocalStorageByKey(key: string, valueData: string) {
      localStorage.setItem(key, valueData);
    },
    cleanLocalStorageByKey(key: string) {
      localStorage.setItem(key, "");
    },
    removeLocalStorageByKey(key: string) {
      localStorage.removeItem(key);
    },
    getLocalLanguage() {
      return this.getLocalStorageByKey(appDecl.currentLang, "en");
    },
    setLocalLanguage(lang: string) {
      return this.setLocalStorageByKey(appDecl.currentLang, lang);
    },
    toBoolFromStr(nbrStr: string): boolean {
      return nbrStr === "0" ? false : nbrStr === "1" ? true : false;
    },
    onlyCapitalLetters: (str: string): string => {
      let newStr = "";

      for (let i = 0; i < str.length; i++) {
        if (str[i].match(/[A-Z]/)) {
          newStr += str[i];
        }
      }
      return newStr;
    },
    onlySmallLetters: (str: string): string => {
      let newStr = "";

      for (let i = 0; i < str.length; i++) {
        if (str[i].match(/[a-z]/)) {
          newStr += str[i];
        }
      }
      return newStr;
    },
    onlySpecialChars: (strSource: string, specialChars: string): string => {
      const retArr: string[] = [];
      const arrSource = strSource.split("");
      arrSource.forEach((oSrc) => {
        if (specialChars.includes(oSrc)) {
          retArr.push(oSrc);
        }
      });
      return retArr.join("");
    },
    passwordStrengthCheck(password: string): number {
      let retFinal = 0;
      const pLength = password.length;
      //const regNbr = new RegExp('^[0-9]+$');
      if (/\d/.test(password)) {
        retFinal += 5;
        const tempoPw = password;
        const allNbrs = tempoPw.replace(/[^0-9]/g, "");
        const lenNbrs = allNbrs.length;
        if (lenNbrs! < 2) {
          retFinal += 5;
        } else if (lenNbrs! === 2) {
          retFinal += 10;
        } else {
          retFinal += 15;
        }
      }
      const regCapLetter = /[A-Z]/;
      if (regCapLetter.test(password)) {
        retFinal += 5;

        const caps = this.onlyCapitalLetters(password).length;
        if (caps < 2) {
          retFinal += 5;
        } else if (caps === 2) {
          retFinal += 10;
        } else {
          retFinal += 15;
        }
      }
      const regSmallLetter = /[a-z]/;
      if (regSmallLetter.test(password)) {
        retFinal += 5;

        const caps = this.onlySmallLetters(password).length;
        if (caps < 2) {
          retFinal += 5;
        } else if (caps === 2) {
          retFinal += 10;
        } else {
          retFinal += 15;
        }
      }
      const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      if (specialChars.test(password)) {
        retFinal += 5;
        const caps = this.onlySpecialChars(
          password,
          "`!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~"
        ).length;
        if (caps < 2) {
          retFinal += 5;
        } else if (caps === 2) {
          retFinal += 10;
        } else {
          retFinal += 15;
        }
      }
      if (pLength >= 8) {
        retFinal += 20;
      }
      return retFinal;
    },
    getManagtDefaultHeaders(): HeaderType {
      const newDate = new Date();
      const accesskey = this.getLocalStorageByKey(
        appDecl.userAccessKey,
        ""
      );
      const lang = this.getLocalLanguage();
      const companyCode = appDecl.company_code;
      const operationDate = this.dateToUtcStr(new Date());

      let year_app = this.getLocalStorageByKey(
        appDecl.currentYearbook,
        newDate.getFullYear().toString()
      );
      const default_sms = this.getLocalStorageByKey(
        appDecl.currentDefaultSmsAcct,
        ""
      );
      const current_devise = this.getLocalStorageByKey(
        appDecl.currentDevise,
        "USD"
      );
      const current_branch = this.getLocalStorageByKey(
        appDecl.currentBranch,
        "HQ"
      );
      const user_email_value = this.getLocalStorageByKey(
        appDecl.userEmail,
        ""
      );
      const user_password_value = this.getLocalStorageByKey(
        appDecl.userPassword,
        ""
      );

      const oDate = new Date();
      const bInt = !Number.isNaN(year_app);
      if (!bInt) {
        year_app = oDate.getFullYear().toString();
      }

      return {
        "user-access-key": accesskey ? btoa(accesskey) : "",
        lang: lang ? btoa(lang) : "",
        "company-code": companyCode ? btoa(companyCode) : "",
        "client-operation-date": btoa(operationDate),
        "year-exercise-app": btoa(year_app),
        "default-sms-account": default_sms ? btoa(default_sms) : "",
        "current-devise-val": btoa(current_devise),
        "current-branch-val": btoa(current_branch),
        "user-email-value": user_email_value ? btoa(user_email_value) : "",
        "user-password-value": user_password_value
          ? btoa(user_password_value)
          : "",
        "app-type": btoa("managt"),
      };
    },
    returnLang(englText: string, frText: string, rwText: string): string {
      const lng = this.getLocalLanguage();
      return lng === "en"
        ? englText
        : lng === "fr"
        ? frText
        : lng === "rw"
        ? rwText
        : englText;
    },
    //#endregion

    //#region alerts dialogs
    showInputAlert(msgTitle: string, confirmButtonText: string = "OK") {
      return Swal.fire({
        title: msgTitle,
        input: "text",
        confirmButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    },
    showAlert(
      msgTitle: string,
      msgBody: string,
      cancelButtonText: string = language.cancel
    ) {
      return Swal.fire({
        title: msgTitle,
        text: msgBody,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    },
    showConfirm(
      msgTitle: string,
      msgBody: string,
      cancelButtonText: string = language.cancel,
      confirmButtonText: string = language.yes
    ) {
      return Swal.fire({
        title: msgTitle,
        text: msgBody,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText,
        confirmButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    },
    showAjaxLoader(loadingMessage?: string) {
      const langText = loadingMessage ?? GetLanguage().waitServerResp;

      return Swal.fire({
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        html: `
                    <div class="py-2 flex flex-col items-center justify-center">
                        <img class="animate-spin my-3" src="${loadingAjax}" width="50" height="50" />
                        <span class="py-3">${langText}</span>
                    </div>
                `,
      });
    },
    //#endregion

    //#region strings
    wiseStrings: (str?: string) => {
      return {
        isEmpty: !str || str.length === 0,
      };
    },
    isTelephoneNbrValid(telNbr: string): boolean {
      const phone = telNbr.replace(/[^0-9]/g, "");
      return phone.length === 12;
    },
    isEmailValid(email: string): boolean {
      const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      return reg.test(email);
    },
    replaceByCharacter(str: string, fChar: string, nChar: string): string {
      return str.split(fChar).join(nChar);
    },
    extractFileNameFromUrl(url: string): string {
      return url.substring(url.lastIndexOf("/") + 1);
    },
    getTextFromHtml(html: string): string {
      const span = $(`<span>${html}</span>`);
      return span.text();
    },
    generateUUID,
    generateTextNbr(length = 8): string {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let str = "";
      for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return str;
    },
    getRandomDigits(length: number): string {
      const chars = "0123456789";
      let str = "";
      for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return str;
    },
    getRandomInt(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    //#endregion

    //#region date and time
    wdMonthsLng,
    wdMonthsShrt,
    getYearFromNow(nYears: number): { id: number; label: string }[] {
      const fY = new Date().getFullYear(),
        lst = [] as { id: number; label: string }[];
      for (let i = 0; i < nYears; i++) {
        const _o = fY + i;
        lst.push({ id: _o, label: _o.toString() });
      }

      return lst;
    },
    getMonthsDates(): { id: number; label: string }[] {
      const lst = [] as { id: number; label: string }[];
      const months = wdMonthsShrt;

      for (let i = 0; i < months.length; i++) {
        const mInt =
          (i + 1).toString().length > 1
            ? (i + 1).toString()
            : "0" + (i + 1).toString();
        const _i = mInt + "-" + months[i];
        lst.push({ id: i + 1, label: _i });
      }

      return lst;
    },
    dateToUtcStr(dDate: Date): string {
      return new Date(dDate).toJSON();
    },
    formatDateWithHrs(
      oDate: Date,
      format: string = "DD/MM/YYYY HH:mm"
    ): string {
      if (oDate) {
        const oFinalDate = oDate;
        return moment(oFinalDate).format(format);
      }

      return "";
    },
    formatDateWithHrsSpec(
      oDate: Date,
      format: string = "MMMM DD, YYYY HH:mm"
    ): string {
      if (oDate) {
        const oFinalDate = oDate;
        return moment(oFinalDate).format(format);
      }

      return "";
    },
    formatDate(oDate: Date, format: string = "DD/MM/YYYY"): string {
      if (oDate) {
        return moment(oDate).format(format);
      }

      return "";
    },
    formatDateFr(oDate: Date, bTime: boolean = false): string {
      const format = bTime ? "DD/MM/YYYY hh:mm:ss" : "DD/MM/YYYY";
      if (oDate) {
        return moment(oDate).format(format);
      }

      return "";
    },
    formatDateEn(oDate: Date): string {
      if (oDate) {
        return moment(oDate).format("MM/DD/YYYY");
      }

      return "";
    },
    addingDate(
      _date: Date,
      _oper: DateOperation,
      _add: number,
      bSub: boolean = false
    ): Date {
      let _dateRet = new Date();

      try {
        _dateRet = new Date(_date.getTime());

        if (_oper === DateOperation.Day) {
          _dateRet.setDate(_dateRet.getDate() + 1 * _add);
        } else if (_oper === DateOperation.Month) {
          _dateRet.setMonth(_dateRet.getMonth() + 1 * _add);
        } else if (_oper === DateOperation.Year) {
          _dateRet.setFullYear(_dateRet.getFullYear() + 1 * _add);
        }
      } catch (e) {
        return new Date();
      }
      if (bSub === true) {
        _dateRet.setDate(_dateRet.getDate() + 1 * -1);
      }
      return _dateRet;
    },
    getFirstDate(year: number, month: number): Date {
      return new Date(year, month - 1, 1);
    },
    getLastDate(year: number, month: number): Date {
      return new Date(year, month, 0);
    },
    getFirstDateByDate(referDate: Date): Date {
      const year = referDate.getFullYear();
      const month = referDate.getMonth() + 1;

      return new Date(year, month - 1, 1);
    },
    getLastDateByDate(referDate: Date): Date {
      const year = referDate.getFullYear();
      const month = referDate.getMonth() + 1;

      return new Date(year, month, 0);
    },
    isBetweenTwoDates(
      _referDate: Date,
      _firstDate: Date,
      _lastDate: Date
    ): boolean {
      const referDate = new Date(
        _referDate.getFullYear(),
        _referDate.getMonth(),
        _referDate.getDate(),
        23,
        59,
        0
      );
      const firstDate = new Date(
        _firstDate.getFullYear(),
        _firstDate.getMonth(),
        _firstDate.getDate(),
        23,
        59,
        0
      );
      const lastDate = new Date(
        _lastDate.getFullYear(),
        _lastDate.getMonth(),
        _lastDate.getDate(),
        23,
        59,
        0
      );
      return (
        referDate.getTime() >= firstDate.getTime() &&
        referDate.getTime() <= lastDate.getTime()
      );
    },
    isDateValidComparator(dateData: Date, dateRef: Date): boolean {
      const oDateData = new Date(
        dateData.getFullYear(),
        dateData.getMonth(),
        dateData.getDate(),
        23,
        59,
        0
      );
      const oDateRef = new Date(
        dateRef.getFullYear(),
        dateRef.getMonth(),
        dateRef.getDate(),
        23,
        59,
        0
      );

      return oDateData <= oDateRef;
    },
    //#endregion

    //#region number
    isNbrOdd: (oNb: number): boolean => {
      const oRes = oNb % 2;
      return oRes > 0;
    },
    formatNumbers(val: number): string {
      return Intl.NumberFormat("en-US").format(val);
    },
    numberOnly(str: string): boolean {
      if (typeof str !== "string") {
        return false;
      }

      if (str.trim() === "") {
        return false;
      }

      return !Number.isNaN(Number(str));
    },
    strictlyNbr(evt: any) {
      const event = evt ? evt : window.event;
      const charCode = event.which ? event.which : event.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    numberComma(evt: any, vValue: string): boolean {
      const isDot = evt.charCode === 46;
      const isNbr = (evt.charCode >= 48 && evt.charCode <= 57) || isDot;
      const hasDot = vValue.indexOf(".") !== -1;
      if (isDot) {
        if (hasDot) evt.preventDefault();
      } else {
        if (!isNbr) evt.preventDefault();
      }

      return true;
    },
    numberOnlyV2(evt: any): boolean {
      return evt.charCode >= 48 && evt.charCode <= 57;
    },
    floatNbr(event: any) {
      const input = event.target as HTMLInputElement;
      if (
        (event.which !== 46 || input.value.indexOf(".") !== -1) &&
        (event.which < 48 || event.which > 57)
      ) {
        event.preventDefault();
      }
    },
    floatNbrT(event: any) {
      const input = event.target as HTMLInputElement;
      if (
        event.which === 8 ||
        event.keyCode === 37 ||
        event.keyCode === 39 ||
        event.keyCode === 46
      )
        return true;
      else if (
        (event.which !== 46 || input.value.indexOf(".") !== -1) &&
        (event.which < 48 || event.which > 57)
      )
        return false;
    },
    formatNbrShorten(value: number, bCap: boolean = true): string {
      let newValue = value.toString();
      if (value >= 1000) {
        const suffixes = ["", "k", "m", "b", "t"];
        const suffixNum = Math.floor(("" + value).length / 3);
        let shortValue = 0;
        for (let precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum !== 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          const dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        /* let shortNum = "";
                if (shortValue % 1 !== 0)  shortNum = shortValue.toFixed(1); */
        newValue = shortValue + suffixes[suffixNum];
      }
      return bCap
        ? newValue
          ? newValue.toString().toUpperCase()
          : newValue
        : newValue;
    },
    randomIntFromInterval: (min:number, max:number) => {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    //#endregion

    //#region objects
    getObjWithoutPerop(obj: any, propertyName: string) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [propertyName]: unused, ...rest } = obj;
      return rest;
    },
    mergeObjects(firstObject: any, lastObject: any) {
      const finalObject = { ...firstObject, ...lastObject };

      return finalObject;
    },
    getFormData(xObject?: any): FormData {
      const form_data = new FormData();

      if (xObject) {
        for (const key in xObject) {
          form_data.append(key, xObject[key]);
        }
      }
      return form_data;
    },
    getProxyData(proxyData: any): any {
      return JSON.parse(JSON.stringify(proxyData));
    },
    //#endregion

    //#region cart
    //#endregion

    //#region  arrays
    randomElement: (array: string[]) => {
      return array[Math.floor(Math.random() * array.length)];
    },
    //#endregion
  };
}

export default generalFx;
