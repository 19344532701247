export enum DateOperation {
    Day,
    Month,
    Year,
}
export type HeaderType = {
    'user-access-key': string;
    lang: string;
    'company-code': string;
    'client-operation-date': string;
    'year-exercise-app': string;
    'default-sms-account': string;
    'current-devise-val': string;
    'current-branch-val': string;
    'user-email-value': string;
    'user-password-value': string;
    'app-type': string;
}


export interface IContactUsPageMessage {
    fullName: string;
    emailAddress: string;
    subject: string;
    messageContent: string;
  }












