import appDeclarations from "./declarations";

const getAppLang = (): string => {
  const elt = localStorage.getItem(appDeclarations.currentLang);
  return elt ?? "en";
};

const retLang = (
  enCont: string,
  frCont: string,
  rwCont: string = ""
): string => {
  const lang = getAppLang();

  return lang === "en"
    ? enCont
    : lang === "fr"
    ? frCont
    : lang === "rw"
    ? rwCont
    : enCont;
};

function GetLanguage() {
  return {
    loading: retLang("Loading", "Chargement", ""),
    login: retLang("Login", "Connection", ""),
    loggin: retLang("Logging In", "Connection", ""),

    password: retLang("Password", "Mot de passe", ""),
    signInTo: retLang("Sign in to", "Connectez-vous à", ""),
    signIn: retLang("Sign in", "Connection", ""),
    signOut: retLang("Sign out", "Se deconnecter", ""),
    signOutMsg: retLang(
      "Do you want to sign out?",
      "Voulez-vous vous déconnecter?",
      ""
    ),
    rememberMe: retLang("Remember me", "Souviens-toi de moi", ""),
    forgotPassWord: retLang("Forgot password?", "Mot de passe oublié?", ""),
    appName: retLang("Archivix App", "Archivix App", ""),
    dashboard: retLang("Dashboard", "Tableau de bord", ""),
    blog: retLang("Blog", "Journal", ""),
    socialevents: retLang("Social events", "Evénements sociaux", ""),
    messages: retLang("Messages", "Messages", ""),
    events: retLang("Events", "Evénements", ""),
    calendar: retLang("Calendar", "Calendrier", ""),
    menu: retLang("Menu", "Menu", ""),

    networkError: retLang("Network error", "Erreur de réseau", ""),
    networkErrorMsg: retLang(
      "There is a network error on this device. Check this issue and try again.",
      "Il y a une erreur réseau sur cet appareil. Vérifiez ce problème et réessayez.",
      ""
    ),
    fldRequired: retLang(
      "This field is required",
      "Ce champ est obligatoire",
      ""
    ),
    emailFormat: retLang(
      "The email format seems wrong.",
      "Le format de l'e-mail semble incorrect.",
      ""
    ),
    passLengthMsg: retLang(
      "Password must be at least 8 characters.",
      "Le mot de passe doit comporter au moins 8 caractères.",
      ""
    ),
    passValidMsg: retLang(
      "The password strength is too weak to be valid.",
      "Le niveau de sécurité du mot de passe est trop faible pour être valide.",
      ""
    ),
    sameAsPasswordMsg: retLang(
      "You are unable to confirm your password correctly.",
      "Vous n'arrivez pas à confirmer votre mot de passe correctement.",
      ""
    ),

    phonenbrLengthMsg: retLang(
      "The phone number must be 12 caracters long.",
      "Le numéro de téléphone doit être de 12 caractères.",
      ""
    ),

    jan_lng: retLang("January", "Janvier", "Mutarama"),
    feb_lng: retLang("February", "Février", "Gashyantare"),
    mar_lng: retLang("March", "Mars", "Werurwe"),
    apr_lng: retLang("April", "Avril", "Mata"),
    may_lng: retLang("May", "Mai", "Gicurasi"),
    jun_lng: retLang("Jun", "Juin", "Kamena"),
    jul_lng: retLang("July", "Juillet", "Nyakanga"),
    aug_lng: retLang("August", "Août", "Kanama"),
    sept_lng: retLang("September", "Septembre", "Nzeri"),
    oct_lng: retLang("October", "Octobre", "Ukwakira"),
    nov_lng: retLang("November", "Novembre", "Ugushyingo"),
    dec_lng: retLang("December", "Decembre", "Ukuboza"),

    jan_shrt: retLang("Jan", "Janr", "Muta"),
    feb_shrt: retLang("Feb", "Fév", "Gashy"),
    mar_shrt: retLang("Mar", "Mars", "Weru"),
    apr_shrt: retLang("Apr", "Avr", "Mata"),
    may_shrt: retLang("May", "Mai", "Gicu"),
    jun_shrt: retLang("Jun", "Juin", "Kame"),
    jul_shrt: retLang("July", "Juil", "Nyaka"),
    aug_shrt: retLang("Aug", "Août", "Kana"),
    sept_shrt: retLang("Sept", "Sept", "Nzeri"),
    oct_shrt: retLang("Oct", "Oct", "Ukwa"),
    nov_shrt: retLang("Nov", "Nov", "Ugus"),
    dec_shrt: retLang("Dec", "Dec", "Uku"),

    monday_lng: retLang("Monday", "Lundi", ""),
    tuesday_lng: retLang("Tuesday", "Mardi", ""),
    wednday_lng: retLang("Wednesday", "Mercredi", ""),
    thursday_lng: retLang("Thursday", "Jeudi", ""),
    friday_lng: retLang("Friday", "Vendredi", ""),
    saturday_lng: retLang("Saturday", "Samedi", ""),
    sunday_lng: retLang("Sunday", "Dimance", ""),

    monday_shrt: retLang("Mon", "Lun", ""),
    tuesday_shrt: retLang("Tue", "Mar", ""),
    wednday_shrt: retLang("Wed", "Mer", ""),
    thursday_shrt: retLang("Thu", "Jeu", ""),
    friday_shrt: retLang("Fri", "Ven", ""),
    saturday_shrt: retLang("Sat", "Sam", ""),
    sunday_shrt: retLang("Sun", "Dim", ""),

    english_m: retLang("English", "Anglais", ""),
    french_m: retLang("French", "Français", ""),
    rwanda_m: retLang("Kinyarwanda", "Kinyarwanda", ""),
    swahili_m: retLang("Swahili", "Swahili", ""),
    lingala_m: retLang("Lingala", "Lingala", ""),
    other_m: retLang("Other language", "Autre langue", ""),
    reload: retLang("Reload", "Recharger", ""),
    cancel: retLang("Cancel", "Annuler", ""),
    yes: retLang("Yes", "Oui", ""),

    waitServerResp: retLang(
      "Waiting for server response...",
      "Veuillez patienter la reponse du serveur...",
      ""
    ),

    professional: retLang("Professional", "Professionel", ""),
    chooseFree: retLang("Choose Free", "Choisir Free", ""),
    fiftSignDocsPartMonth: retLang(
      "15 documents to sign/partners per month",
      "15 documents à signer/partenaires par mois",
      ""
    ),
    fiftSignDocsSatffMonth: retLang(
      "15 documents to sign/staff per month",
      "15 documents à signer/personnel par mois",
      ""
    ),
    fiftDocsMonth: retLang(
      "15 documents per month",
      "15 documents par mois",
      ""
    ),
    fiftDaysFree: retLang("15 days free", "15 jours gratuits", ""),
    noCredCardReq: retLang(
      "No credit card required",
      "Aucune carte de crédit n'est requise",
      ""
    ),
    free: retLang("Free", "Free", ""),
    reset: retLang("Reset", "Réinitialiser", ""),
    support: retLang("Support", "Aide", ""),
    pricing: retLang("Pricing", "Prix", ""),
    close: retLang("Close", "Fermer", ""),
    allRightsRes: retLang("All rights reserved.", "Tous droirs reservés.", ""),
    contactSales: retLang(
      "Contact Sales",
      "Contacter le service commercial",
      ""
    ),
    docArch: retLang("Archives and Signing", "Archivages et Signature.", ""),
    manSecDocs: retLang(
      "manage securely yours docs",
      "gérez vos documents en toute sécurité",
      ""
    ),
    contactUCustomQte: retLang(
      "Contact us for a custom quote and a custom onboarding process.",
      "Contactez-nous pour un devis personnalisé et un processus d'intégration personnalisé.",
      ""
    ),
    enterprise: retLang("Enteprise", "Enteprise", ""),
    chooseStarter: retLang("Choose Starter", "Choisir Démarrage", ""),
    unlimUsers: retLang("Unlimitted users", "Utilisateurs illimités", ""),
    fiftyDocsMonth: retLang("50 documents / month", "50 documents / mois", ""),
    everInFree: retLang(
      "Everything in Free",
      "Tout ce qui est dans Gratuit",
      ""
    ),
    knowledgeBase: retLang("Knowledge Base", "Base de connaissances", ""),
    phoneSupport: retLang("Phone Support", "Assistance par téléphone", ""),
    emailSupport: retLang("Email Support", "Assistance par e-mail", ""),
    developper: retLang("Developper", "Développeur", ""),
    doctMgtApp: retLang(
      "Document Management Software",
      "Application de Gestion des Documents",
      ""
    ),
    websites: retLang("Websites", "Sites web", ""),
    choosePro: retLang("Choose Pro", "Choisir le Pro", ""),
    chooseStartup: retLang("Choose Startup", "Choisir le Startup", ""),

    partnOps: retLang("Partner ops", "Operations partenaires", ""),
    signatures: retLang("Signatures", "Signatures", ""),
    documents: retLang("Documents", "Documents", ""),
    month: retLang("Month", "Mois", ""),
    startup: retLang("Startup", "Démarrage", ""),
    everyThStarter: retLang("Everything in Starter", "Tout dans Démarrage", ""),

    everyInStart: retLang("Everything in Startup", "Tout dans le Startup", ""),
    digitalSignature: retLang("Digital signature", "Signatures digitale", ""),
    unlimitSigns: retLang("Unlimitted signatures", "Signatures illimitées", ""),
    unlimitParts: retLang("Unlimitted partners", "Partenaires illimitées", ""),
    unlimitDocts: retLang(
      "Unlimitted documents",
      "Nbr .documents illimités",
      ""
    ),
    unlimitUsers: retLang("Unlimitted users", "Utilisateurs illimités", ""),
    billedAnnUser: retLang(
      "billed annually/user",
      "facturé annuellement/utilisateur",
      ""
    ),
    billedAnn: retLang("billed annually", "facturé annuellement", ""),
    billedMon: retLang("billed monthly", "facturé mensuellement", ""),
    twoWeeksFree: retLang("2 weeks free", "2 semaines gratutes", ""),
    annually: retLang("Annually", "Annuellement", ""),
    monthly: retLang("Monthly", "Mensuellement", ""),
    perMonthUser: retLang("per month/user", "par mois/utilisateur", ""),
    starter: retLang("Starter", "Entrée", ""),
    legal: retLang("Legal", "Juridique", ""),
    typeMsg: retLang("Type your message here", "Tapez votre message ici", ""),
    subject: retLang("Subject", "Objet", ""),
    email: retLang("Email", "Email", ""),
    name: retLang("Name", "Nom", ""),
    send: retLang("Send", "Envoyer", ""),
    home: retLang("Home", "Acceuil", ""),
    lastUpdated: retLang("Last updated", "Dernière mise à jour", ""),
    termsOfUse: retLang("Terms of use", "Conditions d'utilisation", ""),
    privPolicy: retLang("Privacy policy", "Politique de confidentialité", ""),
    privPolicySh: retLang("Privacy policy", "Confidentialité", ""),
    cookiePolicy: retLang("Cookie policy", "Politique de cookies", ""),
    cookiePolicySh: retLang("Cookie policy", "Cookies", ""),
    applePlayStore: retLang("Apple App Store", "Apple App Store", ""),
    googlePlayStore: retLang("Google Play Store", "Google Play Store", ""),
    features: retLang("Features", "Fonctionnalités", ""),
    doctOrg: retLang("Document Organization", "Organisation des documents", ""),
    doctCollab: retLang(
      "Document Collaboration",
      "Collaboration documentaire",
      ""
    ),
    doctSec: retLang("Document Security", "Sécurité des documents", ""),
    free3RdPartyTools: retLang(
      "Free Third Party Mobile Tools",
      "Outils mobiles tiers gratuits",
      ""
    ),
    featureTitle: retLang(
      "Document management software and signing automation",
      "Logiciel de gestion de documents et automatisation des signatures",
      ""
    ),
    featureTitle2: retLang(
      `
      In every business, efficiency is key. Streamline your workflow with our document management app. From organizing files to collaboration, it's the ultimate tool for boosting productivity. Say hello to seamless document handling and goodbye to chaos. Try it today and experience the difference!
      `,
      `
      Dans toute entreprise, l'efficacité est la clé. Rationalisez votre flux de travail avec notre application de gestion de documents. De l'organisation des fichiers à la collaboration, c'est l'outil ultime pour augmenter la productivité. Dites bonjour à la gestion transparente des documents et adieu au chaos. Essayez-le aujourd'hui et découvrez la différence!
      `,
      ""
    ),
    freeWeSeries: retLang(
      "Free webinar series",
      "Série de webinaires gratuits, partout",
      ""
    ),
    freeWeSeriesMSg: retLang(
      `
      Unlock the power of efficient document management with our free webinar series! Join us for expert insights, tips, and hands-on demonstrations tailored to maximize your experience with Archivix. Don't miss this opportunity to streamline your workflow and revolutionize document handling. Reserve your spot today!
      `,
      `
      Libérez la puissance d'une gestion efficace des documents avec notre série de webinaires gratuits ! Rejoignez-nous pour des conseils d'experts, des conseils et des démonstrations pratiques conçues pour maximiser votre expérience avec Archivix. Ne manquez pas cette opportunité de rationaliser votre flux de travail et de révolutionner la gestion des documents. Réservez votre place dès aujourd'hui!
      `,
      ""
    ),
    easyBus: retLang(
      "Every business, Everywhere",
      "Chaque entreprise, partout",
      ""
    ),
    contactUs: retLang("Contact Us", "Nous Contacter", ""),
    fillUpForm: retLang(
      "Fill up the form below to send us a message.",
      "Remplissez le formulaire ci-dessous pour nous envoyer un message.",
      ""
    ),
    urMsgSent: retLang(
      "Your message has been sent. Check your email for Acknowledgement. Thank you for contacting Us!",
      "Votre message a été envoyé. Vérifiez votre courrier électronique pour l'accusé de réception. Merci de nous contacter!",
      ""
    ),

    noPostBlogYet: retLang(
      "We're working hard to update you with tits on how you can benefit from Archivix while managing your documents. We'll post as soon as possible.",
      "Nous travaillons dur pour vous informer sur la manière dont vous pouvez bénéficier d'Archivix tout en gérant vos documents. Nous publierons dès que possible.",
      ""
    ),
    howSchoolArchEff: retLang(
      `How schools can boost efficiency with Archivix`,
      `Comment les écoles peuvent améliorer leur efficacité avec Archivix`,
      ""
    ),
    moreDetails: retLang("More details", "Plus de détails", ""),
    howSchoolArchEffMsg: retLang(
      `
      Schools can boost efficiency with Archivix by digitizing paperwork processes. 
      Instead of manually handling stacks of papers for tasks like 
      enrollment forms, permission slips, or staff contracts, these 
      apps allow schools to manage documents digitally. With signature 
      automation, staff, parents, and students can electronically 
      sign documents, eliminating the need for physical signatures 
      and paper-based workflows. This streamlines administrative 
      tasks, reduces paperwork, speeds up processes, and ensures 
      document security and compliance. Overall, document management 
      apps and signature automation help schools operate more 
      efficiently, allowing staff to focus on educational 
      priorities rather than administrative burdens.
      `,
      `
      Les écoles peuvent améliorer leur efficacité avec Archivix en 
      numérisant les processus administratifs. Au lieu de gérer 
      manuellement des piles de papiers pour des tâches telles que 
      les formulaires d'inscription, les fiches d'autorisation ou 
      les contrats du personnel, ces applications permettent aux 
      écoles de gérer les documents numériquement. Grâce à 
      l'automatisation des signatures, le personnel, les 
      parents et les élèves peuvent signer électroniquement 
      des documents, éliminant ainsi le besoin de signatures 
      physiques et de flux de travail sur papier. Cela rationalise 
      les tâches administratives, réduit la paperasse, accélère 
      les processus et garantit la sécurité et la conformité des 
      documents. Dans l'ensemble, les applications de gestion de 
      documents et l'automatisation des signatures aident les 
      écoles à fonctionner plus efficacement, permettant au 
      personnel de se concentrer sur les priorités éducatives 
      plutôt que sur les charges administratives.
      `,
      ""
    ),
    ourCustomers: retLang("Our customers", "Nos clients", ""),
    ourCustomersMsg: retLang(
      "Many customers are adhering to Archivix. They find out it's a convenient tool to manage their document, automating their signature processus as well as digitizing their business. Sign up today andd grow up the Archivix community of users.",
      "De nombreux clients adhèrent à Archivix. Ils découvrent que c'est un outil pratique pour gérer leur document, automatiser leur processus de signature ainsi que numériser leur entreprise. Inscrivez-vous aujourd'hui et développez la communauté d'utilisateurs d'Archivix.",
      ""
    ),
    coreBusDoctM: retLang(
      "Document management and your core business",
      "La gestion documentaire et votre cœur de métier",
      ""
    ),
    doctManSol: retLang(
      "Document management solutions and signature automation for",
      "Solutions de gestion documentaire et automatisation des signatures pour",
      ""
    ),
    schoolBusdocts: retLang(
      "School business document processing",
      "Traitement des documents professionnels de l'école",
      ""
    ),
    schoolBusdoctsMsg: retLang(
      "From digitizing existing paper documents and establishing a centralized digital repository accessible to staff.",
      "De la numérisation des documents papier existants à la mise en place d'un référentiel numérique centralisé accessible au personnel.",
      ""
    ),
    humanResourc: retLang(
      "Human resources",
      "Ressources humaines",
      ""
    ),
    humanResourcMsg: retLang(
      "Transform your HR department's document handling with our innovative app, simplifying the management of staff documents, from onboarding to performance reviews.",
      "Transformez la gestion des documents de votre service RH avec notre application innovante, simplifiant la gestion des documents du personnel, de l'intégration aux évaluations de performances.",
      ""
    ),
    insuranceBusiness: retLang(
      "Insurance business",
      "Activité d'assurance",
      ""
    ),
    insuranceBusinessMsg: retLang(
      "Revolutionize your insurance business's document handling with our cutting-edge management app, simplifying policy administration, claims processing, and compliance management.",
      "Révolutionnez la gestion des documents de votre entreprise d'assurance avec notre application de gestion de pointe, simplifiant l'administration des polices, le traitement des réclamations et la gestion de la conformité.",
      ""
    ),
    schoolEffDoctMan: retLang(
      "School efficiency with document management app",
      "Efficacité scolaire avec une application de gestion de documents",
      ""
    ),
    schoolEff: retLang(
      "School efficiency",
      "Efficacité scolaire",
      ""
    ),
    insurEffDoctMan: retLang(
      "Insurance efficiency with Archivix",
      "Efficacité de l'assurance avec Archivix",
      ""
    ),
    insurEffDoctManMsg: retLang(
      "Our document management app optimizes efficiency within insurance companies by digitizing document workflows, accelerating claims processing, and ensuring compliance with industry regulations. With streamlined access to policy documents, customer records, and claims information, our solution empowers insurance professionals to expedite processes, enhance collaboration, and deliver exceptional service to clients.",
      "Notre application de gestion documentaire optimise l'efficacité au sein des compagnies d'assurance en numérisant les flux de documents, en accélérant le traitement des réclamations et en garantissant la conformité aux réglementations du secteur. Avec un accès simplifié aux documents de police, aux dossiers clients et aux informations sur les réclamations, notre solution permet aux professionnels de l'assurance d'accélérer les processus, d'améliorer la collaboration et de fournir un service exceptionnel aux clients.",
      ""
    ),
    viewAllWebin: retLang(
      "View all webinars",
      "Voir tous les webinaires",
      ""
    ),
    schools: retLang("Schools","Ecoles",""),
    insurance: retLang("Insurance","Assurance",""),
    individuals: retLang("Individuals","Individus",""),
    
    indivEffDoctMan: retLang(
      "Individuals efficiency with Archivix",
      "Efficacité des individus avec Archivix",
      ""
    ),
    indivEffDoctManMsg: retLang(
      "Our document management app empowers individuals to take control of their digital documents, streamlining organization, access, and collaboration. With intuitive features tailored for personal use, such as easy file categorization, seamless sharing, and robust security measures, our app ensures efficient management of documents, saving time and reducing stress in daily life.",
      "Notre application de gestion de documents permet aux individus de prendre le contrôle de leurs documents numériques, en rationalisant l'organisation, l'accès et la collaboration. Avec des fonctionnalités intuitives adaptées à un usage personnel, telles qu'une catégorisation facile des fichiers, un partage transparent et des mesures de sécurité robustes, notre application garantit une gestion efficace des documents, un gain de temps et une réduction du stress dans la vie quotidienne.",
      ""
    ),
    individualsFPar: retLang(
      "A document management application can greatly assist individuals in various aspects of their personal and professional lives:",
      "Une application de gestion documentaire peut grandement aider les individus dans divers aspects de leur vie personnelle et professionnelle:",
      ""
    ),
  };
}

export default GetLanguage;
