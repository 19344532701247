//const pathUrl = 'http://localhost:5959';
//const mainAppPath = 'http://localhost:8989';
const apiPathUrl = 'https://api.archivix.net';
const mainAppPath = 'https://app.archivix.net';

const appDeclarations = {
    currentLang: 'current_language',
    connectionStatus: 'connect_status',
    apiRootTest: `${apiPathUrl}/api/`,
    apiUrlTest: apiPathUrl,
    mainAppPath: mainAppPath,
    registerPath: `${mainAppPath}/account/register`,

    userAccessKey: 'user_access_key',

    buyerAccessKey: 'buyer___access___key',

    company_code: 'WBTARCHAPP001-UPMANGT',
    currentYearbook: 'current_year_book',

    consentData: 'consent___data___',
    consentEmail: 'consent___email___',
    consentName: 'consent___name___',
    consentWeb: 'consent___web___',


    currentDefaultSmsAcct: 'current_def_sms_acct',
    itemsPerPage: 'items_per_page',
    currentDevise: 'current_devise',
    currentBranch: 'current_branch',
    userEmail: 'user_email',
    userPassword: 'userPassword',
    cartZoneArea: 'cart___zone_area____2363',
    mainPhoneNbr: '250 788 510 589',
    displayPrivSett: 'display_priv_sett_____025',
}


export default appDeclarations;