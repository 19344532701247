import React, { useEffect } from "react";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

import bg2 from "src/assets/images/bg-school-1.jpg";
import schoolBus1 from "src/assets/images/school_bus_1.jpg";
import schoolBus2 from "src/assets/images/school_bus_2.jpg";
import schoolBus3 from "src/assets/images/school_bus_3.jpg";
import schoolBus4 from "src/assets/images/school_bus_4.jpg";
import schoolBus5 from "src/assets/images/school_bus_5.jpg";

const languages = getLang();
const appFxs = generalFxs();

const locLang = appFxs.getLocalLanguage();

export default function FeaturesSchoolPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${languages.schoolEffDoctMan} | Archivix`;
  }, []);

  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="relative bg-left bg-no-repeat bg-cover"
      >
        <div className="absolute z-0 bg-gradient-to-r from-black w-full h-full"></div>
        <div className="container mx-auto py-12 relative z-[1]">
          <h3 className="py-3 text-[45px] text-white">{languages.schoolEff}</h3>
          <p
            data-lang={locLang}
            className="data-[lang='fr']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Our document management app revolutionizes efficiency in schools by
            digitizing paperwork, streamlining administrative tasks, and
            enhancing collaboration among staff. With features tailored to
            educational needs, such as centralized document storage, seamless
            sharing, and automated workflows, it simplifies processes and
            empowers educators to focus on what truly matters: educating
            students.
          </p>
          <p
            data-lang={locLang}
            className="data-[lang='en']:hidden w-full md:w-1/2 mt-4 text-xl text-white mb-4"
          >
            Notre application de gestion documentaire révolutionne l'efficacité
            dans les écoles en numérisant la paperasse, en rationalisant les
            tâches administratives et en améliorant la collaboration entre le
            personnel. Avec des fonctionnalités adaptées aux besoins éducatifs,
            telles que le stockage centralisé de documents, le partage
            transparent et les flux de travail automatisés, il simplifie les
            processus et permet aux enseignants de se concentrer sur ce qui
            compte vraiment : former les étudiants.
          </p>
        </div>
      </div>

      <div className="w-full">
        <div className="container mx-auto mb-6">
          <div className="flex flex-col py-12">
            {/* Centralized Document Repository */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 order-1">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Centralized Document Repository</>
                  ) : (
                    <>Référentiel centralisé de documents</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Schools deal with a multitude of documents, including student
                  records, administrative paperwork, lesson plans, and more. A
                  document management application provides a centralized
                  repository where all these documents can be stored digitally.
                  This eliminates the need for physical storage space, reduces
                  the risk of document loss or damage, and ensures easy access
                  to information whenever needed.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Les écoles traitent une multitude de documents, notamment les
                  dossiers des élèves, les documents administratifs, les plans
                  de cours, etc. Une application de gestion de documents fournit
                  un référentiel centralisé où tous ces documents peuvent être
                  stockés numériquement. Cela élimine le besoin d'espace de
                  stockage physique, réduit le risque de perte ou de dommage des
                  documents et garantit un accès facile aux informations en cas
                  de besoin.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 order-2">
                <img src={schoolBus1} alt="" className="w-full aspect-video" />
              </div>
            </div>

            {/* Efficient Collaboration */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 md:order-2">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Efficient Collaboration and Communication</>
                  ) : (
                    <>Collaboration et communication efficaces</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  With features like real-time collaboration and file sharing,
                  teachers, administrators, and staff can work together more
                  effectively. They can collaborate on lesson plans, share
                  resources, and communicate important announcements or updates
                  easily within the application. This enhances teamwork,
                  improves efficiency, and fosters a more cohesive school
                  community.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Grâce à des fonctionnalités telles que la collaboration en
                  temps réel et le partage de fichiers, les enseignants, les
                  administrateurs et le personnel peuvent travailler ensemble
                  plus efficacement. Ils peuvent collaborer sur des plans de
                  cours, partager des ressources et communiquer facilement des
                  annonces ou des mises à jour importantes au sein de
                  l'application. Cela améliore le travail d'équipe, améliore
                  l'efficacité et favorise une communauté scolaire plus
                  cohésive.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 md:order-1">
                <img src={schoolBus2} alt="" className="w-full aspect-video" />
              </div>
            </div>

            {/* Enhanced Security and Compliance */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Enhanced Security and Compliance</>
                  ) : (
                    <>Sécurité et conformité améliorées</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  Student privacy and data security are paramount in schools. A
                  document management application offers robust security
                  features such as role-based access control, encryption, and
                  audit trails to safeguard sensitive information. This ensures
                  compliance with data protection regulations such as FERPA
                  (Family Educational Rights and Privacy Act) and GDPR (General
                  Data Protection Regulation) and provides peace of mind to
                  administrators, parents, and students.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  La confidentialité des étudiants et la sécurité des données
                  sont primordiales dans les écoles. Une application de gestion
                  de documents offre des fonctionnalités de sécurité robustes
                  telles que le contrôle d'accès basé sur les rôles, le cryptage
                  et les pistes d'audit pour protéger les informations
                  sensibles. Cela garantit le respect des réglementations en
                  matière de protection des données telles que la FERPA (Family
                  Educational Rights and Privacy Act) et le GDPR (General Data
                  Protection Règlement) et offre une tranquillité d'esprit aux
                  administrateurs, aux parents et aux étudiants.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3">
                <img src={schoolBus3} alt="" className="w-full aspect-video" />
              </div>
            </div>

            {/* Streamlined Administrative Processes */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3 md:order-2">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Streamlined Administrative Processes</>
                  ) : (
                    <>Processus administratifs rationalisés</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  From enrollment forms to permission slips, schools deal with a
                  myriad of administrative paperwork. A document management
                  application automates and streamlines these processes,
                  allowing for electronic document submission, approval
                  workflows, and digital signatures. This reduces paperwork,
                  minimizes manual errors, and speeds up administrative tasks,
                  freeing up valuable time for educators to focus on teaching
                  and student support.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Des formulaires d'inscription aux fiches d'autorisation, les
                  écoles doivent gérer une myriade de formalités
                  administratives. Une application de gestion de documents
                  automatise et rationalise ces processus, permettant la
                  soumission électronique de documents, les flux de travail
                  d'approbation et les signatures numériques. Cela réduit la
                  paperasse, minimise les erreurs manuelles et accélère les
                  tâches administratives, libérant ainsi un temps précieux pour
                  les enseignants qui peuvent se concentrer sur l'enseignement
                  et le soutien aux étudiants.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3 md:order-1">
                <img src={schoolBus4} alt="" className="w-full aspect-video" />
              </div>
            </div>

            {/* Improved Accessibility and Accountability */}
            <div className="flex flex-wrap justify-between mb-6">
              <div className="w-full md:w-[49%] mb-3">
                <h3 className="text-2xl font-semibold text-neutral-700 py-4">
                  {locLang === "en" ? (
                    <>Improved Accessibility and Accountability</>
                  ) : (
                    <>Accessibilité et responsabilité améliorées</>
                  )}
                </h3>
                <p
                  data-lang={locLang}
                  className="data-[lang='fr']:hidden text-xl"
                >
                  With documents stored digitally, authorized users can access
                  them from anywhere, anytime, using any device with an internet
                  connection. This improves accessibility for teachers,
                  students, and parents, especially in scenarios such as remote
                  learning or parent-teacher conferences. Additionally, document
                  management applications maintain detailed audit trails,
                  tracking document access and modifications, which promotes
                  accountability and transparency within the school environment.
                </p>
                <p
                  data-lang={locLang}
                  className="data-[lang='en']:hidden text-xl"
                >
                  Avec les documents stockés numériquement, les utilisateurs
                  autorisés peuvent y accéder de n'importe où, à tout moment, en
                  utilisant n'importe quel appareil doté d'une connexion
                  Internet. Cela améliore l'accessibilité pour les enseignants,
                  les élèves et les parents, en particulier dans des scénarios
                  tels que l'apprentissage à distance ou les conférences
                  parents-enseignants. De plus, les applications de gestion de
                  documents maintiennent des pistes d'audit détaillées,
                  permettant de suivre l'accès aux documents et leurs
                  modifications, ce qui favorise la responsabilité et la
                  transparence au sein de l'environnement scolaire.
                </p>
              </div>
              <div className="w-full md:w-[49%] mb-3">
                <img src={schoolBus5} alt="" className="w-full aspect-video" />
              </div>
            </div>
          </div>

          <div className="w-full md:w-[60%] mr-2 mx-auto">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <p
                data-lang={locLang}
                className="data-[lang='fr']:hidden text-gray-700"
              >
                Overall, a document management application can streamline
                operations, enhance collaboration, and improve data security and
                compliance, making it an invaluable tool for modern schools
                striving for efficiency and excellence.
              </p>
              <p
                data-lang={locLang}
                className="data-[lang='en']:hidden text-gray-700"
              >
                Dans l'ensemble, une application de gestion documentaire peut
                rationaliser les opérations, améliorer la collaboration et
                améliorer la sécurité et la conformité des données, ce qui en
                fait un outil inestimable pour les écoles modernes en quête
                d'efficacité et d'excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
